import { Component, computed, inject, signal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from 'src/app/_services/auth.service';
import { PlanPriceComponent } from './plan-price/plan-price.component';
import { TotalSearchesCardComponent } from './total-searches-card/total-searches-card.component';
import { PlanStatusPillComponent } from './plan-status-pill/plan-status-pill.component';
import { SubscriptionService } from 'src/app/_services/subscription.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';
import { SubscriptionTier } from '../../_types/user';
import { PricingPlansComponent } from './pricing-plans/pricing-plans.component';
import { ModalComponent } from '../../modal/modal.component';
import { EventBusService } from '../../_shared/event-bus.service';
import { Router } from '@angular/router';
import { PlanUsageComponent } from './plan-usage/plan-usage.component';
@Component({
  selector: 'subscriptions',
  standalone: true,
  imports: [FontAwesomeModule, PricingPlansComponent, ModalComponent, CommonModule, PlanUsageComponent],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly subscriptionService = inject(SubscriptionService);
  private readonly eventBusService = inject(EventBusService);
  private readonly router = inject(Router);

  isSuperUser = this.authService.superUser;
  manageBillingLinkIcon = faArrowUpRightFromSquare;
  showUpgradeModal = signal(false);
  showThankYouModal = signal(false);
  subscriptionTiers = signal<SubscriptionTier[]>([]);
  showOrderConfirmModal = signal(false);
  isUpgrade = signal(false);
  confirmPlan = signal<SubscriptionTier | null>(null);

  availablePlans = computed(() => {
    return this.subscriptionTiers().filter((tier) => tier.ceiling && tier.ceiling <= 1000);
  });

  availablePlansToUpgrade = computed(() => {
    const currentUser = this.user();
    if (!currentUser) {
      return [];
    }

    const { customer } = currentUser;
    const { subscriptions } = customer;
    const activeSubscription = subscriptions[0];

    if (!activeSubscription) {
      return [];
    }

    const { subscriptionTier } = activeSubscription;

    const plans = this.subscriptionTiers().filter(
      (tier) =>
        tier.ceiling && tier.ceiling <= 1000 && tier.id !== subscriptionTier.id && tier.price > subscriptionTier.price
    );

    return plans;
  });

  readonly user = this.authService.user;
  readonly hasSubscription = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    const { customer } = user;
    const { subscriptions } = customer;
    return subscriptions && subscriptions.length > 0;
  });

  plan = signal({
    name: 'Free Trial',
    price: '$0',
    period: 'per month',
    description: 'Then $150 per month ($1800/year, billed annually)',
    daysLeft: 7
  });

  searches = signal({
    used: 35,
    total: 50,
    resetDate: '12/10/2024'
  });

  planStatus = computed(() => {
    return `${this.plan().daysLeft} days left`;
  });

  superUserEmail = computed(() => {
    const user = this.authService.user();
    if (user) {
      return user.superUserEmail;
    }
    return null;
  });

  setShowOrderConfirmModal(show: boolean) {
    this.showOrderConfirmModal.set(show);

    if (!show) {
      this.isUpgrade.set(false);
    }
  }

  setConfirmPlan(plan: SubscriptionTier) {
    this.confirmPlan.set(plan);
    this.showOrderConfirmModal.set(true);
  }

  setConfirmUpgrade(plan: SubscriptionTier) {
    this.confirmPlan.set(plan);
    this.isUpgrade.set(true);
    this.showOrderConfirmModal.set(true);
  }

  confirmPayment(upgrade: boolean = false) {
    const planToPurchase = this.confirmPlan();
    if (!planToPurchase) {
      this.eventBusService.emit('ErrorPageBanner.Show', 'No plan selected. Please select a plan to continue.');
      return;
    }

    const newSubscription = upgrade
      ? this.subscriptionService.upgradeSubscription(planToPurchase.id)
      : this.subscriptionService.createSubscription(planToPurchase.id);

    newSubscription.subscribe({
      next: () => {
        this.showOrderConfirmModal.set(false);
        this.eventBusService.emit('StartTrialSubscription.Show', planToPurchase.ceiling);
        this.router.navigate(['/']);
      },
      error: (error) => {
        console.error(error);
        this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
      }
    });
  }

  requestUpgrade() {
    this.subscriptionService.sendUpgradeSubscriptionEmail().subscribe({
      next: () => {
        this.showUpgradeModal.set(true);
      },
      error: (error) => {
        this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
      }
    });
  }

  ngOnInit(): void {
    this.subscriptionService.getSubscriptionTiers().subscribe({
      next: (tiers) => {
        this.subscriptionTiers.set(tiers);
      }
    });
  }
}
