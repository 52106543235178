import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureService } from './feature.service';
import { map, Observable } from 'rxjs';

export class SuperAdminService {
  router: Router = inject(Router);
  featureService: FeatureService = inject(FeatureService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.featureService.getFeatures().pipe(
      map((userFeatures) => {
        this.featureService.features.set(userFeatures);
        const isSuperAdmin = this.featureService.enabled('super-admin');
        if (!isSuperAdmin) {
          this.router.navigate(['/error']);
          return false;
        }
        return true;
      })
    );
  }
}
