import { NgIf, NgClass } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'search-ui-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, NgIf, NgClass]
})
export class BadgeComponent implements OnInit, OnDestroy {
  @Input() label: string | undefined;
  @Input() icon: IconDefinition | undefined;
  @Input() iconColor: string | undefined;
  @Input() color: string | undefined;

  public ngOnInit(): void {
    this.iconColor = this.iconColor || '#0090FF';
    this.color = this.color || '#f2f2f2';
  }

  public ngOnDestroy(): void {}
}
