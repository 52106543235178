<div class="plan-and-usage-container">
  <div class="section-header">{{ !hasSubscription() && isSuperUser() ? 'Pricing Plans' : 'Current Plan' }}</div>
  @if (isSuperUser()) { @if (!hasSubscription()) {
  <pricing-plans (confirmPlan)="setConfirmPlan($event)" [plans]="availablePlans()" [actionButtonText]="'Choose Plan'" />

  } @else {
  <div class="plan-container">
    <plan-usage />
    <div class="upgrade-plans-title">More Pricing Plans</div>
    <div class="upgrade-plans-divider">
      <svg xmlns="http://www.w3.org/2000/svg" width="476" height="2" viewBox="0 0 476 2" fill="none">
        <path d="M0 1H476" stroke="#E8E8E8" />
      </svg>
    </div>

    <pricing-plans
      (confirmPlan)="setConfirmUpgrade($event)"
      [plans]="availablePlansToUpgrade()"
      [actionButtonText]="'Upgrade Now'"
      [emptyListMessage]="'You are currently on the highest-tier plan.'"
    />
  </div>

  } } @else {
  <div class="plan-container">
    <plan-usage />
  </div>
  <button (click)="requestUpgrade()" class="upgrade-button">Request to Upgrade</button>
  } @if (showOrderConfirmModal()) {
  <modal (close)="setShowOrderConfirmModal(false)">
    <div class="order-confirm-modal-container">
      <div class="title">Order Summary</div>
      <div class="items">
        <div class="price-quantity">
          <div class="quantity">{{ confirmPlan()?.ceiling | number }} searches per year</div>
          <div class="price">${{ confirmPlan()?.price | number }}/yr</div>
        </div>
        <div class="message">(Searches do not roll over)</div>
      </div>
      <div class="subtotal-container">
        <div>Subtotal</div>
        <div>${{ confirmPlan()?.price | number }}</div>
      </div>
      <div class="total-container">
        <div class="order-confirm-total-title">Due Today</div>
        <div class="order-confirm-total-amount">${{ confirmPlan()?.price | number }}</div>
      </div>
      <div class="order-confirm-footer">
        <div class="footer-text">
          The plan will be added to your existing Limble CMMS contract and will renew with your CMMS subscription.
        </div>
        <div class="privacy-policy">
          By clicking Confirm, you agree to our <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a>.
        </div>
      </div>
      <div class="order-confirm-actions">
        <button (click)="confirmPayment(this.isUpgrade())" class="order-confirm-button">Confirm Payment</button>
      </div>
    </div>
  </modal>
  } @if (showUpgradeModal()) {
  <modal (close)="showUpgradeModal.set(false)">
    <div class="upgrade-modal-container">
      <div class="icon">
        <img src="assets/images/email-icon.svg" alt="email-icon" />
      </div>
      <div class="title">Thanks for Your Interest in Upgrading!</div>
      <div class="description">
        Stay tuned! We’ve emailed your administrator (<span>{{ superUserEmail() }}</span
        >) about upgrading your Limble Search plan and cc’d you for reference.
      </div>
      <div class="actions">
        <button (click)="showUpgradeModal.set(false)">Done</button>
      </div>
    </div>
  </modal>
  }
</div>
