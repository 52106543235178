import { Component, computed, inject, output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
import { EventBusService } from '../_shared/event-bus.service';
import { AuthService } from '../_services/auth.service';
import { SubscriptionService } from '../_services/subscription.service';
@Component({
  selector: 'search-limit-modal',
  templateUrl: './search-limit-modal.component.html',
  styleUrls: ['./search-limit-modal.component.scss'],
  standalone: true,
  imports: [ModalComponent]
})
export class SearchLimitModalComponent {
  private readonly router = inject(Router);
  private readonly eventBusService = inject(EventBusService);
  private readonly authService = inject(AuthService);
  private readonly subscriptionService = inject(SubscriptionService);

  closeModal = output<void>();

  searchesUsed = computed(() => {
    const user = this.authService.user();

    if (!user) {
      return 0;
    }

    const { customer } = user;
    const { subscriptions } = customer;
    const subscription = subscriptions[0];

    if (!subscription) {
      return 0;
    }

    const { subscriptionTier } = subscription;
    const { ceiling } = subscriptionTier;

    return ceiling ?? 0;
  });

  superUser = computed(() => this.authService.superUser());

  handleClickUpgrade() {
    if (this.superUser()) {
      this.router.navigate(['/account/subscriptions']);
      this.handleCloseModal();
    } else {
      this.requestUpgrade();
    }
  }

  handleCloseModal() {
    this.closeModal.emit();
    this.eventBusService.emit('INIT', null);
  }

  requestUpgrade() {
    this.subscriptionService.sendUpgradeSubscriptionEmail().subscribe({
      next: () => {
        this.handleCloseModal();
      },
      error: (error) => {
        this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
      }
    });
  }
}
