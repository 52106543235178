import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CONFIG } from 'src/environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription, SubscriptionTier, User } from '../_types/user';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

const subscriptionUrl = CONFIG.API_URL + 'subscription';
const trialUrl = CONFIG.API_URL + 'trial';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private user = this.authService.user;

  sendUpgradeSubscriptionEmail() {
    if (!this.user()) {
      throw new Error('User not found');
    }

    if (this.user() && this.user()?.superUser) {
      return this.http.post(subscriptionUrl + '/upgrade', { companyName: this.user()?.customer.name }, httpOptions);
    }

    return this.http.post(subscriptionUrl + '/upgrade/request', { userId: this.user()?.id }, httpOptions);
  }

  upgradeSubscription(subscriptionTierId: number) {
    const user = this.user();
    if (!user) {
      throw new Error('User not found');
    }

    if (user && user.superUser) {
      return this.http.post<Subscription>(subscriptionUrl + '/upgrade', { subscriptionTierId }, httpOptions).pipe(
        tap((subscription) => {
          const currentUser = this.user();
          if (currentUser && subscription.subscriptionTier && subscription.subscriptionTier.ceiling) {
            const currentSubscription = currentUser.customer.subscriptions[0];
            let searchesRemaining = currentUser.searchesRemaining;
            if (
              currentSubscription &&
              currentUser.searchesRemaining !== null &&
              currentSubscription.subscriptionTier.ceiling
            ) {
              const ceilingDiff = subscription.subscriptionTier.ceiling - currentSubscription.subscriptionTier.ceiling;
              searchesRemaining = currentUser.searchesRemaining + ceilingDiff;
            }
            this.authService.saveUser({
              ...currentUser,
              customer: {
                ...currentUser.customer,
                subscriptions: [subscription]
              },
              searchesRemaining
            });
          }
        })
      );
    }

    return this.http.post(subscriptionUrl + '/upgrade/request', { userId: user.id }, httpOptions);
  }

  sendTrialRequestEmail() {
    const user = this.user();
    if (!user) {
      throw new Error('User not found');
    }

    const { superUserEmail } = user;

    if (!superUserEmail) {
      throw new Error('No super user email found');
    }

    return this.http.post(trialUrl + '/request', { superUserEmail }, httpOptions);
  }

  createSubscription(subscriptionTierId: number) {
    const user = this.user();
    if (!user) {
      throw new Error('User not found');
    }

    const { customer } = user;

    return this.http.post<Subscription>(subscriptionUrl, { customerId: customer.id, subscriptionTierId }, httpOptions).pipe(
      tap((subscription) => {
        const currentUser = this.user();
        if (currentUser) {
          this.authService.saveUser({
            ...currentUser,
            customer: {
              ...currentUser.customer,
              subscriptions: [subscription]
            },
            searchesRemaining: subscription.subscriptionTier.ceiling ?? 0
          });
        }
      })
    );
  }

  getSubscriptionTierByName(name: string) {
    return this.http.get<SubscriptionTier>(subscriptionUrl + '/tier', {
      ...httpOptions,
      params: { name }
    });
  }

  getSubscriptionTiers() {
    return this.http.get<SubscriptionTier[]>(subscriptionUrl + '/tiers', httpOptions);
  }

  createTrialSubscription() {
    return this.getSubscriptionTierByName('20 searches').pipe(
      switchMap((subscriptionTier) => {
        return this.createSubscription(subscriptionTier.id);
      })
    );
  }
}
