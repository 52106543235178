<div class="plan-usage-card">
  <div class="header">
    <div class="title">
      <span>{{ searchesPerYear() }}</span> searches per year <span class='price'>(${{ subscriptionPrice() | number }}/yr)</span>
    </div>
  </div>
  <div class="counter-container">
    <div class="title">Total searches used</div>
    <div class="counter">
      <span>{{ searchesUsed() }}</span> / {{ searchesPerYear() }}
    </div>
    @if (outOfSearches()) {
    <div class="progress-bar-complete"></div>
    } @else {
    <div class="progress-bar">
      <div class="progress-bar-fill" [style.width]="progressBarWidth()"></div>
    </div>
    }
    <div class="footer">
      @if (renewalDate()) { This reflects your team’s usage and will reset on {{ renewalDate() }}. Unused searches do not
      roll over. }
    </div>
  </div>
</div>
