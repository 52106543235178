import { Component, inject, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../_services/auth.service';
import { parseISO, format, addDays } from 'date-fns';

@Component({
  selector: 'plan-usage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plan-usage.component.html',
  styleUrls: ['./plan-usage.component.scss']
})
export class PlanUsageComponent {
  private readonly authService = inject(AuthService);

  searchesPerYear = computed(() => {
    const user = this.authService.user();

    if (user) {
      const subscription = user.customer.subscriptions[0];
      console.log('subscription', subscription);
      if (subscription) {
        return subscription.subscriptionTier.ceiling;
      }
    }

    return 0;
  });

  searchesUsed = computed(() => {
    const user = this.authService.user();
    if (user) {
      const subscription = user.customer.subscriptions[0];
      if (subscription) {
        if (!subscription.subscriptionTier.ceiling) {
          return null;
        }

        if (user.searchesRemaining === null) {
          return null;
        }
        return subscription.subscriptionTier.ceiling - user.searchesRemaining;
      }
    }
    return 0;
  });

  outOfSearches = computed(() => {
    return this.searchesUsed() === this.searchesPerYear();
  });

  progressBarWidth = computed(() => {
    const searchesUsed = this.searchesUsed();
    const searchesPerYear = this.searchesPerYear();
    if (searchesUsed && searchesPerYear) {
      return Math.round((searchesUsed / searchesPerYear) * 100) + '%';
    }
    return '0%';
  });

  renewalDate = computed(() => {
    const user = this.authService.user();
    if (user) {
      const subscription = user.customer.subscriptions[0];
      if (subscription) {
        const renewalDate = parseISO(subscription.currentPeriodEnd);
        return format(addDays(renewalDate, 1), 'MMM d, yyyy');
      }
    }
    return null;
  });

  subscriptionPrice = computed(() => {
    const user = this.authService.user();

    if (user) {
      const subscriptionPrice = user.customer.subscriptions[0].subscriptionTier.price;
      console.log('subscription', subscriptionPrice);
      return subscriptionPrice;
    }

    return 0;
  });
}
