import { Component, computed, effect, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgIf, NgFor, AsyncPipe, NgStyle, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';
import { faFilterList, faChevronDown, faChevronUp, faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { PartSearchResultsGroupedComponent } from '../part-search-results-grouped/part-search-results-grouped.component';
import { FilterComponent } from '../filter/filter.component';
import { CheckboxComponent, IconComponent, DropdownTextItemComponent, DropdownComponent } from '@limblecmms/lim-ui';
import { RadioButtonComponent } from '../custom-lim-ui-components/radio-button/radio-button.component';
import { CustomPanelComponent } from '../custom-lim-ui-components/panel/panel.component';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { DeliveryLocationComponent } from '../delivery-location/delivery-location.component';
import { SkeletonLoadingBarAnimation } from '../animations/skeleton-loading-bar-animation/skeleton-loading-bar-animation';
import { SkeletonSortAnimation } from '../animations/skeleton-sort-animation/skeleton-sort-animation';
import { EventBusService } from '../_shared/event-bus.service';
import { AuthService } from '../_services/auth.service';
import { FeatureService } from '../_services/feature.service';
import { AttributeGroup, SelectedAttributeGroups } from '../_types/attributeGrouping';

interface SelectedVendors {
  [key: string]: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgStyle,
    NgFor,
    FormsModule,
    PartSearchResultsGroupedComponent,
    CheckboxComponent,
    IconComponent,
    SkeletonLoadingBarAnimation,
    PartSearchFieldComponent,
    DeliveryLocationComponent,
    FontAwesomeModule,
    SkeletonSortAnimation,
    DropdownComponent,
    RadioButtonComponent,
    DropdownTextItemComponent
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  Math: any = Math;

  public isLoggedIn = this.authService.isLoggedIn;
  public fields: Array<any> = [];
  public searchedFields: Array<any> = [];
  public fieldSearchQuery: string = '';
  public readonly globalMinPrice = computed(() => this.searchService.filteredSearchResultsMeta()?.globalLowestPrice ?? null);
  public readonly globalMaxPrice = computed(
    () => this.searchService.filteredSearchResultsMeta()?.globalHighestPrice ?? null
  );
  public readonly vendorCounts = computed(() => this.searchService.searchResultsMeta()?.vendorCounts ?? []);
  public readonly fastVendorCounts = computed(
    () =>
      this.searchService
        .searchResultsMeta()
        ?.vendorCounts?.filter(
          (vendor) => vendor.isFast && vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0
        )
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName)) ?? []
  );
  public readonly slowVendorCounts = computed(
    () =>
      this.searchService
        .searchResultsMeta()
        ?.vendorCounts.filter(
          (vendor) => !vendor.isFast && vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0)
        )
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName)) ?? []
  );
  public readonly hiddenVendorCounts = computed(
    () =>
      this.searchService
        .searchResultsMeta()
        ?.vendorCounts.filter((vendor) => !vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0))
        .sort((a, b) => a.vendorName.localeCompare(b.vendorName)) ?? []
  );

  public readonly selectedVendors = computed<SelectedVendors>(() => {
    const filters = this.searchService.filters();
    if (filters && typeof filters.vendorId !== 'undefined') {
      const updatedSelectedVendors: SelectedVendors = {};

      let selectedCount = 0;
      filters.vendorId.values.forEach((vendorId) => {
        updatedSelectedVendors[vendorId] = true;
        selectedCount++;
      });

      return updatedSelectedVendors;
    }

    return {};
  });

  public maxPrice: number | null = null;
  public minPrice: number | null = null;
  // public readonly maxPrice = computed(() => this.searchService.filters()?.pricePer?.values[1] ?? null);
  // public readonly minPrice = computed(() => this.searchService.filters()?.pricePer?.values[0] ?? null);

  public dataSourcesLoading: Array<any> = [];
  public allVendorsSelected = computed(() => {
    const selectedVendors = this.selectedVendors();
    const vendorCounts = this.vendorCounts();
    return (
      Object.keys(selectedVendors).length ===
      vendorCounts.filter((vendor) => vendor.itemCount > 0 && vendor.userEnabled).length
    );
  });
  public showLoadingBar = computed(() => !this.searchService.searchComplete());
  public isMobile: boolean = false;
  public faFilterList = faFilterList;
  public faSparkles = faSparkles;
  public showHiddenVendors: boolean = false;
  public sortByLabels: any = {
    'price_per:asc': 'Price (Low to High)',
    'price_per:desc': 'Price (High to Low)',
    relevance: 'Relevance'
  };
  public showSortFilter: boolean = false;
  public showSignupBanner: boolean = true;

  public faLockKeyhole: any = faLockKeyhole;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public showSmartGroupings = computed(() => {
    const searchResultsMeta = this.searchService.searchResultsMeta();
    return this.attributeGroups().length > 1 && searchResultsMeta?.percentComplete >= 95;
  });

  public attributeGroups = computed(() => this.searchService.searchResultsMeta()?.attributeGroups ?? []);

  public hiddenItemsSub?: Subscription;
  public featureSub?: Subscription;
  public routerSub?: Subscription;

  // This is called when the user clinks the View All link in the toast notification after a search is complete
  @HostListener('window:SelectAllVendors', ['$event'])
  selectAllVendorsHandler(event: any) {
    this.selectAllVendors();
  }

  constructor(
    public searchService: SearchService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly eventBus: EventBusService,
    private authService: AuthService,
    private featureService: FeatureService,
    private location: Location
  ) {
    //deep-linking use case
    this.startSearchFromUrl();

    if (this.authService.isLoggedIn()) {
      this.showSignupBanner = false;
    }

    effect(() => {
      const item = this.searchService.selectedDetailsPageItem();
      if (!item || typeof item.id === 'undefined' || !item.id) {
        return;
      }

      //scroll to item if navigating from details page
      const itemTarget = `item${item.id}`;
      setTimeout(() => {
        const itemRef = document.getElementById(itemTarget);
        itemRef?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
      }, 700);
    });
  }

  clickSignupBanner() {
    window.location.href = 'https://limblecmms.com/search-waitlist';
  }

  closeSignupBanner() {
    this.showSignupBanner = false;
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.route.params.subscribe((params) => {
      if (typeof params.searchHistoryId !== 'undefined') {
        this.searchService.isPastResult.set(true);
        this.searchService.getLatestResults(params.searchHistoryId);
      }

      if (typeof params.searchSlug !== 'undefined') {
        this.searchService.isMarketingMode.set(true);
        this.searchService.isPastResult.set(true);
        this.searchService.getLatestResults(params.searchSlug);
      }
    });
  }

  checkItemsViewed(): void {
    if (!this.searchService.searchComplete() && !this.searchService.isPreviewing()) {
      return;
    }

    // Track which items are being viewed
    // Delay the event to make sure item cards are all rendered
    setTimeout(() => {
      let event = new CustomEvent('itemView');
      window.dispatchEvent(event);
    }, 2000);
  }

  ngOnDestroy(): void {
    this.hiddenItemsSub?.unsubscribe();
    this.featureSub?.unsubscribe();
    this.routerSub?.unsubscribe();
  }

  startSearchFromUrl(): void {
    // If a search is already in progress, or we are viewing past results, do not start a new search
    if (this.searchService.isSearching() || this.searchService.isPastResult()) {
      return;
    }

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentNavigation = this.router.getCurrentNavigation();
        const previousNav = currentNavigation?.previousNavigation;
        const extractedUrl = currentNavigation?.extractedUrl;
        let queryPresent = false;
        let queryParams;
        if (extractedUrl && extractedUrl.queryParams) {
          const q = extractedUrl.queryParams.q;
          queryParams = extractedUrl.queryParams;
          if (q && q.length > 0) {
            queryPresent = true;
          }
        }
        if (previousNav === null && queryPresent) {
          const { q, sort, 'price-min': priceMin, 'price-max': priceMax } = queryParams;

          if (priceMin) {
            this.minPrice = Number(priceMin);
          }

          if (priceMax) {
            this.maxPrice = Number(priceMax);
          }

          if (priceMin || priceMax) {
            this.filterByPrice();
          }

          if (sort) {
            this.updateSortBy(sort);
          }

          this.searchService.query.set(q);
          this.searchService.search(q);
          this.router.navigate(['/searching'], { skipLocationChange: true, replaceUrl: true });
        }
      }
    });
  }

  forceInteger(event: any): void {
    event.target.value = parseInt(event.target.value, 10) || '';
  }

  updateSelectedVendors(id: number): void {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    const current = this.selectedVendors();
    const idStr = id.toString();

    if (current.hasOwnProperty(idStr)) {
      current[idStr] = !current[idStr];
    } else {
      current[idStr] = true;
    }

    this.filterByVendor(current);
  }

  updateSelectedAttributGroups(attributeGroup: AttributeGroup) {
    const updatedAttributeGroups: SelectedAttributeGroups = {
      [attributeGroup.id]: attributeGroup
    };

    this.searchService.selectedAttributeGroups.set(updatedAttributeGroups);
    this.searchService.processResults();
  }

  onlyVendor(id: number): void {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    const selectedVendors = { [id]: true };
    this.filterByVendor(selectedVendors);
  }

  selectAllVendors(): void {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    const currentSelectedVendors: SelectedVendors = {};

    this.vendorCounts().forEach((vendor) => {
      if (vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0) {
        currentSelectedVendors[vendor.vendorId] = true;
      }
    });

    this.filterByVendor(currentSelectedVendors);
  }

  deselectAllVendors(): void {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    const currentSelectedVendors: SelectedVendors = {};

    this.vendorCounts().forEach((vendor) => {
      if (vendor.userEnabled && vendor.status !== 'loading' && vendor.itemCount > 0) {
        currentSelectedVendors[vendor.vendorId] = false;
      }
    });

    this.filterByVendor(currentSelectedVendors);
  }

  replaceSelectedVendors(id: number) {
    const current = this.selectedVendors();
    for (let vendorId in current) {
      if (vendorId === id.toString()) {
        current[vendorId] = true;
      } else {
        current[vendorId] = false;
      }
    }
    this.filterByVendor(current);
  }

  filterByVendor(currentSelectedVendors: SelectedVendors) {
    const vendorFilter = {
      field: 'vendorId',
      values: [] as Number[],
      operator: 'includes'
    };
    for (let vendorId in currentSelectedVendors) {
      if (currentSelectedVendors[vendorId]) {
        vendorFilter.values.push(Number(vendorId));
      }
    }
    this.searchService.addFilter(vendorFilter);
    this.searchService.processResults();
  }

  async filterByPrice() {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    const priceFilter = {
      field: 'pricePer',
      values: [] as Number[],
      operator: 'range'
    };
    this.minPrice === null ? priceFilter.values.push(-Infinity) : priceFilter.values.push(this.minPrice);
    this.maxPrice === null ? priceFilter.values.push(Infinity) : priceFilter.values.push(this.maxPrice);
    const updatedQueryString = this.searchService.getUpdatedUrl(this.minPrice, this.maxPrice);
    if (updatedQueryString) {
      this.location.replaceState(
        '/results',
        new URLSearchParams(updatedQueryString as unknown as Record<string, string>).toString()
      );
    }
    await this.searchService.addFilter(priceFilter);
    this.searchService.processResults();
  }

  updateSortBy(sortBy: string): void {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }

    this.searchService.sortBy.set(sortBy);
    this.searchService.processResults();
  }
  getSortByLabel() {
    if (this.searchService.sortBy() === null) {
      return this.sortByLabels['relevance'];
    }
    return this.sortByLabels[this.searchService.sortBy()];
  }

  sortResults(value: any) {
    this.searchService.sortBy.set(value);
    this.searchService.processResults();
  }

  toggleSortAndFilter() {
    if (this.searchService.isItemsRestricted()) {
      this.clickSignupBanner();
      return;
    }
    this.showSortFilter = !this.showSortFilter;
  }
}
