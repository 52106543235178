<div id="app" [attr.app-version]="version">
  <navigation [isMobile]="isMobile()" [isLoggedIn]="isLoggedIn()" [responsivePositioning]="responsivePositioning()" />
  <search-complete-notification />
  <main [ngClass]="responsivePositioning() ? 'main-static' : 'main-fixed'">
    @if (userAccessStatus() === userAccessStatusEnum.SearchCountExceeded) {
    <page-banner class="search-count-exceeded">
      <div content class="msg">
        Your team has reached the search limit until {{ periodResetDate() }}. Upgrade to unlock more searches.
        <a routerLink="/account/subscriptions" class="cta">Upgrade Now</a>
      </div>
    </page-banner>
    } @else if (routeExtensionBannerEnabled() && showExtensionBanner) {
    <page-banner (closedBanner)="handleBannerClose()">
      <div icon>
        <div class="pill">New</div>
      </div>
      <div content class="msg">
        Use Limble Search on Any Website.
        <a routerLink="/extension" class="cta">Learn More</a>
      </div>
    </page-banner>
    } @else if (searchesRemaining() <= 5 && isLoggedIn() && isSubscribed() && !unlimitedSearches()) {
    <page-banner class="low-searches-remaining">
      <div content class="msg">
        Your team has <span class="bold">{{ searchesRemaining() }}</span>
        {{ searchesRemaining() === 1 ? 'search' : 'searches' }} remaining until {{ periodResetDate() }}. Upgrade to unlock
        more searches.
        <a routerLink="/account/subscriptions" class="cta">Upgrade Now</a>
      </div>
    </page-banner>
    } @if (pageBannerErrorMessage()) {
    <page-banner class="error-page-banner">
      <div icon>
        <img src="assets/images/error-icon.png" alt="Error" />
      </div>
      <div content class="msg">
        {{ pageBannerErrorMessage() }}
      </div>
    </page-banner>
    } @if (showSearchesExceededModal()) {
    <search-limit-modal (closeModal)="handleCloseSearchLimitModal()" />
    }
    <router-outlet></router-outlet>
  </main>
</div>
