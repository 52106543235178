import { inject, Injectable, signal, computed, Signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../../environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  features = signal<Record<string, boolean>>({});
  private readonly http = inject(HttpClient);

  enabled(feature: string): boolean {
    const features = this.features();
    return typeof features[feature] !== 'undefined' && features[feature] === true;
  }

  setFeatures(features: Record<string, boolean>): void {
    this.features.set(features);
  }

  getFeatures(): Observable<any> {
    const request = this.http.get(CONFIG.API_URL + 'features', httpOptions);
    return request;
  }

  setUserFeatures(): void {
    this.getFeatures().subscribe({
      next: (data: any) => {
        this.features.set(data);
      }
    });
  }
}
