import { Component, OnDestroy, OnInit, effect, computed } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { Subscription, combineLatest } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { isMobile } from '../_util/mobile.util';

import { ModalService, MinimalIconButtonComponent, Aliases } from '@limblecmms/lim-ui';
import { ItemHiddenNotificationComponent } from '../custom-notifications/item-hidden/item-hidden-notification.component';
import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { FeedbackComponent } from '../feedback-hover/feedback-hover.component';
import { BestMatchResultsComponent } from '../best-match-results/best-match-results.component';
import { DeliveryLocationComponent } from '../delivery-location/delivery-location.component';
import { PreSearchHeroComponent } from '../pre-search-hero/pre-search-hero.component';

@Component({
  selector: 'part-search-results-grouped',
  templateUrl: './part-search-results-grouped.component.html',
  styleUrls: ['./part-search-results-grouped.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    ItemHiddenNotificationComponent,
    PartSearchItemCardComponent,
    BestMatchResultsComponent,
    MinimalIconButtonComponent,
    DeliveryLocationComponent,
    PreSearchHeroComponent
  ]
})
export class PartSearchResultsGroupedComponent implements OnInit, OnDestroy {
  public math = Math;
  public searchQuery = computed(() => this.searchService.query());
  public itemHiddenNotification: any | null = null;
  public collapsed: boolean = false;
  public collapsedIcon: Aliases = 'angleDownRegular';
  public removeBestMatch: boolean = false;
  public isMobile: boolean = false;
  public displayZipCode: string = '';

  public partNumber = computed(() => this.searchService.queryQuality()?.facets?.part_number_value ?? null);

  public showPartMissing = computed(() => {
    const partNumber = this.partNumber();
    const partNumberFound = this.searchService.partNumberFound();
    const isQueryExpanded = this.searchService.isQueryExpanded();

    if (isQueryExpanded) {
      return false;
    }

    return partNumber && !partNumberFound;
  });

  public relevantResultsPresent = computed(() => {
    const relevantResults = this.searchService.relevantResults();
    const searchComplete = this.searchService.searchComplete();

    if (searchComplete && relevantResults.length === 0) {
      return false;
    }

    return true;
  });

  public slowVendors = computed(() => {
    const meta = this.searchService.searchResultsMeta();
    if (meta && meta.vendorCounts) {
      return meta.vendorCounts.filter((vendor) => {
        return !vendor.isFast && vendor.userEnabled && (vendor.status === 'loading' || vendor.itemCount > 0);
      }).length;
    }
    return 0;
  });

  public hiddenItemNotificationSub?: Subscription;

  constructor(
    public searchService: SearchService,
    public modalService: ModalService,
    public notificationService: NotificationService,
    public router: Router
  ) {
    this.hiddenItemNotificationSub = this.notificationService.hiddenItemNotification$.subscribe((itemHiddenNotification) => {
      this.itemHiddenNotification = itemHiddenNotification;
    });

    effect(() => {
      const error = this.searchService.error();
      if (error?.status === 'system' || error?.status === 'timeout') {
        this.router.navigate(['/error']);
      }
    });

    effect(() => {
      const relevantResults = this.searchService.relevantResults();
      const searchComplete = this.searchService.searchComplete();

      if (searchComplete && relevantResults.length === 0) {
        this.collapsed = true;
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
  }

  submitFeedback() {
    const instance = this.modalService.open(FeedbackComponent);
  }

  handleCollapserClick() {
    this.collapsed = !this.collapsed;
    this.collapsedIcon = this.collapsed ? 'angleUpRegular' : 'angleDownRegular';
  }

  showIrrelevantResults(collapsed: boolean) {
    this.collapsed = collapsed;
    if (collapsed) {
      this.removeBestMatch = true;
    } else {
      this.removeBestMatch = false;
    }
  }

  ngOnDestroy(): void {
    this.hiddenItemNotificationSub?.unsubscribe();
  }
}
