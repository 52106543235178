import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  effect,
  computed,
  signal,
  Signal,
  untracked
} from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, NgClass, CurrencyPipe, AsyncPipe } from '@angular/common';
import { trigger, style, animate, transition, stagger, query } from '@angular/animations';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';
import { FeatureService } from '../_services/feature.service';
import { isMobile } from '../_util/mobile.util';
import { TooltipDirective } from '@limblecmms/lim-ui';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { pairwise, switchMap, filter, take } from 'rxjs/operators';
import { EventBusService } from '../_shared/event-bus.service';

import { DropdownButtonComponent, DropdownTextItemComponent } from '@limblecmms/lim-ui';
import { CustomPanelComponent } from '../custom-lim-ui-components/panel/panel.component';
import { PartSearchItemCardComponent } from '../part-search-item-card/part-search-item-card.component';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { BadgeComponent } from '../badge/badge.component';
import { PreSearchBestMatchComponent } from '../pre-search-best-match/pre-search-best-match.component';

import { ItemLoadingAnimation } from '../animations/item-loading-animation/item-loading-animation';

import { SkeletonSortAnimation } from '../animations/skeleton-sort-animation/skeleton-sort-animation';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';

@Component({
  selector: 'best-match-results',
  templateUrl: './best-match-results.component.html',
  styleUrls: ['./best-match-results.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    CurrencyPipe,
    CustomPanelComponent,
    PartSearchItemCardComponent,
    DropdownButtonComponent,
    DropdownTextItemComponent,
    ItemLoadingAnimation,
    SkeletonSortAnimation,
    ShippingDetailsComponent,
    FontAwesomeModule,
    TooltipDirective,
    BadgeComponent,
    PreSearchBestMatchComponent
  ]
  // Animations sometimes cause glitchy behavior (brief duplicate cards)
  // when messing with vendor filters, disabling for now
  // This seems to only happen on the first interaction with "slow vendors" probably because it swaps the full item
  // list out on first interaction
  //animations: [trigger('hideItem', [transition(':leave', [query('.item', [animate(300, style({ opacity: 0.5 }))])])])]
})
export class BestMatchResultsComponent implements OnInit, OnDestroy {
  @Output() collapseIrrelevantResults: EventEmitter<boolean> = new EventEmitter<boolean>();

  private searchCycle = new BehaviorSubject<number>(0);
  private _heroItemSelectedByUser = false;

  public sortByLabels: any = {
    'price_per:asc': 'Price (Low to High)',
    'price_per:desc': 'Price (High to Low)',
    relevance: 'Relevance'
  };

  public groupedResultLimit: number = 5;
  public dollarCircle = faDollarCircle;
  public lockKeyhole = faLockKeyhole;
  public showLabelControls = false;
  public isMobile: boolean = false;

  public faSparkles = faSparkles;
  public showGroupingBadge = computed(() => {
    const selectedAttributeGroup = this.searchService.selectedAttributeGroups();
    if (!selectedAttributeGroup) {
      return false;
    }

    const group = Object.values(selectedAttributeGroup)[0];
    if (group.name === 'Product') {
      return false;
    }

    return true;
  });
  public badgeLabel = computed(() => {
    const selectedAttributeGroup = this.searchService.selectedAttributeGroups();
    if (selectedAttributeGroup) {
      const group = Object.values(selectedAttributeGroup)[0];
      if (this.isMobile) {
        return `${this.match() && this.match().itemGroupValue ? this.match().itemGroupValue : 'Not Found'}`;
      } else {
        return `${group.name} \u2022 ${
          this.match() && this.match().itemGroupValue ? this.match().itemGroupValue : 'Not Found'
        }`;
      }
    }
    return '';
  });
  public filters: any = {};
  public heroItem = signal<any>(null);

  public sortBySub?: Subscription;
  public featureServiceSub?: Subscription;

  public bestMatch = computed(() => {
    const bestMatchItem = { ...this.searchService.bestMatch() };
    if (this.searchService.isItemsRestricted()) {
      bestMatchItem.isLocked = true;
    }

    return bestMatchItem;
  });

  public showBestMatchContainer = computed(() => {
    const isPreviewing = this.searchService.isPreviewing();
    const hasResults = this.searchService.searchResults().length > 0;
    const removeBestMatch = this.removeBestMatch();
    const bestMatchItem = this.bestMatch();

    if (isPreviewing && hasResults && !removeBestMatch) {
      if (bestMatchItem.productUrl) {
        return true;
      }
    }

    return false;
  });

  public showRelevantResults = computed(() => {
    const relevantResults = this.searchService.relevantResults();

    if (relevantResults && relevantResults.length === 1) {
      return false;
    }

    return true;
  });

  public removeBestMatch = computed(() => {
    if (this.searchService.relevantResults() && this.searchService.relevantResults().length === 0) {
      return true;
    }

    return false;
  });

  public relevantResults = computed(() => {
    const relevantResults = this.searchService.relevantResults();
    if (!relevantResults) {
      return [];
    }

    if (this.removeBestMatch()) {
      return this.searchService.relevantResults();
    }

    return this.searchService.relevantResults().slice(1);
  });

  public pastSearchHistoryResult = computed(() => {
    return this.searchService.isPastResult() || this.searchService.isCachedQuery();
  });

  public preSearchPresent = computed(() => {
    const searchResultsMeta = this.searchService.searchResultsMeta();
    if (searchResultsMeta?.preSearchDetails) {
      return true;
    }
    return false;
  });
  // public preSearchPresent = () => false;

  public items = computed(() => {
    const bestMatchItem = this.searchService.bestMatch();
    const attributeGroup = this.searchService.selectedAttributeGroups();

    if (attributeGroup) {
      const group = Object.values(attributeGroup)[0];
      const match = bestMatchItem?.matches?.find(({ itemGroupName }) => itemGroupName === group?.name);

      if (match && match.items) {
        const filteredGroupItems = this.searchService.filterGroupItems(match.items);
        const uniqueVendorIds = new Set();
        const uniqueVendorItems: any[] = [];
        filteredGroupItems.forEach((item) => {
          if (!uniqueVendorIds.has(item.vendor.id)) {
            uniqueVendorItems.push(item);
            uniqueVendorIds.add(item.vendor.id);
          }
        });

        return uniqueVendorItems.slice(0, 5);
      }
    }

    return [];
  });

  public groupedItems = computed(() => {
    const items = this.items();
    items.forEach((item: any, index: number) => {
      item.isLocked = this.searchService.isItemsRestricted() && index < 3;
    });
    return items;
  });

  public match: Signal<ItemGroupMatch> = computed(() => {
    const bestMatchItem = this.searchService.bestMatch();
    const attributeGroup = this.searchService.selectedAttributeGroups();

    if (attributeGroup) {
      const group = Object.values(attributeGroup)[0];
      const match = bestMatchItem.matches?.find(({ itemGroupName }) => itemGroupName === group?.name);
      if (match && match.items) {
        const filteredGroupItems = this.searchService.filterGroupItems(match.items);
        const vendors = this.searchService.getVendorsFromFilteredGroupItems(filteredGroupItems);
        const uniqueVendorIds = new Set();
        const uniqueVendorItems: any[] = [];
        filteredGroupItems.forEach((item) => {
          if (!uniqueVendorIds.has(item.vendor.id)) {
            uniqueVendorItems.push(item);
            uniqueVendorIds.add(item.vendor.id);
          }
        });

        return { ...match, count: filteredGroupItems.length, vendors };
      }
    }

    return {};
  });

  public vendorLogoKeys = computed(() => {
    const match = this.match();
    const heroItem = this.heroItem();
    if (match && match.vendors && heroItem && heroItem.vendor) {
      const vendorLogoKeys = match.vendors.filter((vendor: any) => vendor.id !== heroItem.vendor.id).map(({ key }) => key);
      return vendorLogoKeys;
    }

    return [];
  });

  public vendorTooltip = computed(() => {
    const vendors = this.match()?.vendors ?? [];

    if (!vendors || vendors.length == 0) {
      return '';
    }

    let tooltip = 'Compare this item on ';

    vendors.forEach((vendor: any, index: number) => {
      tooltip += vendor.name;
      if (index < vendors.length - 2) {
        tooltip += ', ';
      } else if (index === vendors.length - 2) {
        tooltip += ' and ';
      }
    });

    return tooltip;
  });

  public preSearchBestMatchItems = computed(() => {
    const groupedItems = this.groupedItems();
    if (groupedItems.length) {
      return groupedItems.slice(0, 5);
    } else {
      return [this.bestMatch()];
    }
  });

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    private readonly router: Router,
    private featureService: FeatureService,
    private readonly eventBus: EventBusService
  ) {
    effect(
      () => {
        const bestMatchItem = this.bestMatch();
        const bestMatchHasKeys = Object.keys(bestMatchItem).length > 0;
        const items = this.items();
        const currentHeroItem = untracked(() => this.heroItem());

        if (!currentHeroItem && bestMatchItem && bestMatchHasKeys) {
          this.heroItem.set(bestMatchItem);
        } else if (!this._heroItemSelectedByUser && items.length && items[0].id !== currentHeroItem?.id) {
          this.heroItem.set(items[0]);
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const relevantResults = this.searchService.relevantResults();
      if (relevantResults && relevantResults.length === 0) {
        this.collapseIrrelevantResults.emit(true);
      }
    });

    effect(() => {
      const searchComplete = this.searchService.searchComplete();
      const pastSearchHistoryResult = this.pastSearchHistoryResult();
      const removeBestMatch = this.removeBestMatch();
      const preSearchPresent = this.preSearchPresent();
      const preSearchBestMatchItems = this.preSearchBestMatchItems();
      const heroItem = this.heroItem();
      const groupedItems = this.groupedItems();
      const showRelevantResults = this.showRelevantResults();
      const selectedAttributeGroups = this.searchService.selectedAttributeGroups();
      const relevantResults = this.relevantResults();

      if (searchComplete) {
        if (pastSearchHistoryResult) {
          console.log('past search detected');
          return;
        }
        let index = 1;
        if (!removeBestMatch) {
          const groupValueId = this.match()?.itemGroupValueId;
          if (preSearchPresent) {
            preSearchBestMatchItems.forEach((item: any) => {
              this.itemsService.trackPagePosition(item.id, index, groupValueId);
            });
          } else {
            if (heroItem) {
              this.itemsService.trackPagePosition(heroItem.id, index, groupValueId);
            }
            groupedItems.forEach((item: any) => {
              this.itemsService.trackPagePosition(item.id, index, groupValueId);
            });
          }
        }

        if (showRelevantResults) {
          relevantResults.forEach((item: any) => {
            index = index + 1;
            let groupValueId = undefined;
            if (selectedAttributeGroups) {
              const group = Object.values(selectedAttributeGroups)[0];
              if (item.matches) {
                const match = item.matches.find(({ itemGroupId }) => itemGroupId === group?.id);
                if (match) {
                  groupValueId = match.itemGroupValueId;
                }
              }
            }
            this.itemsService.trackPagePosition(item.id, index, groupValueId);
          });
        }
        this.itemsService.savePagePositions();
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    const bestMatch = this.bestMatch();
    const bestMatchHasKeys = Object.keys(bestMatch).length > 0;
    this.showLabelControls = this.featureService.enabled('super-admin');

    // this will set the hero item to best match when coming back from the details page
    if (!this.heroItem() && bestMatch && bestMatchHasKeys) {
      this.heroItem.set(bestMatch);
    }
  }

  openItemSite(productUrl: any, vendor: any, itemId: number) {
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  getSortByLabel() {
    return 'Sort by: ' + this.sortByLabels[this.searchService.sortBy()];
  }

  sortResults(value: any) {
    this.searchService.sortBy.set(value);
    this.searchService.processResults();
  }

  selectItem(item: any) {
    this.heroItem.set(item);
    this._heroItemSelectedByUser = true;
  }

  showDetails(selectedItem: any) {
    if (selectedItem.isLocked) {
      window.location.href = 'https://limblecmms.com/search-waitlist';
      return;
    }

    this.itemsService.trackClick(selectedItem.id, 'details').subscribe();
    const queryParams = { group: this.match()?.itemGroupValueId };
    this.searchService.selectedDetailsPageItem.set(selectedItem);
    this.searchService.startProgressBar();
    this.router.navigate(['/details', `${selectedItem.id}`], { queryParams });
  }

  setLabel(itemId: any, key: string, value: any) {
    if (this.searchService.getItemLabel(itemId, key) == value) {
      value = null;
    }
    this.itemsService.updateLabel(itemId, key, value).subscribe();
    this.searchService.setItemLabel(itemId, key, value);

    return false;
  }

  reset(): void {
    this.itemsService.resetPagePositions();
    this.searchCycle.next(this.searchCycle.value + 1);
  }
  ngOnDestroy(): void {
    this.sortBySub?.unsubscribe();
    this.featureServiceSub?.unsubscribe();
  }
}
