import { Component, OnInit, OnDestroy, effect, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchService } from 'src/app/_services/search.service';
import { of, delay } from 'rxjs';

@Component({
  selector: 'search-complete-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-complete-notification.component.html',
  styleUrls: ['./search-complete-notification.component.scss']
})
export class SearchCompleteNotificationComponent {
  public totalItems: number = 0;
  public toggledMessage = true;

  public showMessage = computed(() => {
    const searchComplete = this.searchService.searchComplete();
    const isDetailsPage = window.location.pathname.includes('details');
    return searchComplete && !isDetailsPage;
  });

  constructor(public searchService: SearchService) {
    effect(() => {
      if (this.searchService.searchComplete()) {
        of(false)
          .pipe(delay(10000))
          .subscribe((value) => {
            this.toggledMessage = value;
          });
      }
    });
  }

  close(): void {
    this.toggledMessage = false;
  }
}
