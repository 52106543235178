import { Component, OnInit, OnDestroy, inject, effect } from '@angular/core';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { interval, take } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { vendorLogos } from '../_util/constants';
import { isMobile } from '../_util/mobile.util';

import { MinimalIconButtonComponent } from '@limblecmms/lim-ui';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';

@Component({
  selector: 'loading-search',
  templateUrl: './loading-search.component.html',
  styleUrls: ['./loading-search.component.scss'],
  standalone: true,
  imports: [NgIf, PartSearchFieldComponent]
})
export class LoadingSearch implements OnInit, OnDestroy {
  public vendors = [...vendorLogos];

  public animationDone: boolean = false;
  public animationNotStarted: boolean = true;
  public navigateToResults: any;
  public progress = 20;
  public searchComplete: boolean = false;
  public isMobile: boolean = false;

  private searchService = inject(SearchService);
  private router = inject(Router);

  constructor() {
    effect(() => {
      const error = this.searchService.error();
      if (error?.status === 'system' || error?.status === 'timeout') {
        this.router.navigate(['/error']);
      }
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    //dummy route for debugging issues on loading state
    if (this.router.url === '/loading') {
      this.shuffleVendors();
      return;
    }

    //shuffle vendors names
    this.shuffleVendors();

    //simulate loading progress
    const bar = document.getElementById('bar');
    interval(1000)
      .pipe(take(5))
      .subscribe({
        next: () => {
          bar?.style.setProperty('--progress', `${this.progress}%`);
          if (this.progress < 80) {
            this.progress += 20;
          }
          //slight increases after 80% to simulate progress bar update to atleast 85% before it navigates to results
          else if (this.progress === 80 || this.progress === 85) {
            this.progress += 5;
          }
        },
        complete: () => {
          const queryParams = this.searchService.getUpdatedUrl();
          this.router.navigate(['/results'], { queryParams });
        }
      });

    const rotateAnimation = document.querySelector('.rotate');
    if (rotateAnimation) {
      rotateAnimation.addEventListener('animationstart', () => {
        this.animationNotStarted = false;
      });
    }

    //wait for the simulated progress bar to reach 85% or the real time loading to reach roughly about 85% before navigating to results
    //if the search is complete before either of them, navigate to results (usually happens with cached queries)

    // this.navigateToResults = setInterval(() => {
    //   if (this.searchService.percentComplete() >= 80 && this.searchService.percentComplete() <= 85) {
    //     this.router.navigate(['/results']);
    //   } else if (this.progress === 85) {
    //     this.router.navigate(['/results']);
    //   } else if (this.searchComplete === true) {
    //     this.router.navigate(['/results']);
    //   }
    // }, 2000);
  }

  shuffleVendors() {
    return this.vendors.sort(() => Math.random() - 0.5);
  }

  ngOnDestroy(): void {
    clearInterval(this.navigateToResults);
  }
}
