import { Component, input, output } from '@angular/core';
import { SubscriptionTier } from '../../../_types/user';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'pricing-plan-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pricing-plan-item.component.html',
  styleUrl: './pricing-plan-item.component.scss'
})
export class PricingPlanItemComponent {
  plan = input.required<SubscriptionTier>();

  selectedPlan = input<SubscriptionTier | null>();

  selectPlan = output<SubscriptionTier>();

  clickPlan() {
    this.selectPlan.emit(this.plan());
  }
}
