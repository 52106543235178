import { Component, computed, effect, Input, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor, CurrencyPipe, NgClass } from '@angular/common';

import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { ItemsService } from '../_services/items.service';
import { FeatureService } from '../_services/feature.service';
import { combineLatest, Subscription } from 'rxjs';
import { isMobile } from '../_util/mobile.util';
import { BadgeComponent } from '../badge/badge.component';

import { TooltipDirective } from '@limblecmms/lim-ui';

import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDollarCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { EventBusService } from '../_shared/event-bus.service';
import { ItemGroupMatch } from '../_types/attributeGrouping';

@Component({
  selector: 'part-search-item-card',
  templateUrl: './part-search-item-card.component.html',
  styleUrls: ['./part-search-item-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CurrencyPipe,
    TooltipDirective,
    NgClass,
    ShippingDetailsComponent,
    FontAwesomeModule,
    BadgeComponent
  ]
})
export class PartSearchItemCardComponent {
  @Input() item = {
    id: 0,
    title: '',
    description: null,
    price: 0,
    pricePer: 0,
    per: 1,
    mainImageUrl: '',
    productUrl: null,
    manufacturerPartNumber: null,
    savings: null,
    vendor: {
      id: null,
      logoUrl: null,
      name: null,
      key: null
    },
    matches: [] as ItemGroupMatch[],
    items: [],
    hideVendorLogo: false,
    user_hidden: false,
    shipping_days: null,
    inStock: false,
    labels: {},
    source: null
  };

  @Input() view = 'all-items';

  public qty: any = 1;
  public itemTitleClamped: boolean = false;
  public isLoading = computed(() => {
    const isPreviewing = this.searchService.isPreviewing();
    if (this.view === 'similar-items') {
      return false;
    }

    return !this.searchService.searchComplete() && !isPreviewing;
  });
  public dollarCircle = faDollarCircle;
  public showLabelControls: boolean = false;
  public vendorTooltip = computed(() => {
    const match = this.match();
    const vendors = (match && match.vendors) || [];

    if (!vendors || vendors.length == 0) {
      return '';
    }

    let tooltip = 'Compare this item on ';

    vendors.forEach((vendor: any, index: number) => {
      tooltip += vendor.name;
      if (index < vendors.length - 2) {
        tooltip += ', ';
      } else if (index === vendors.length - 2) {
        tooltip += ' and ';
      }
    });

    return tooltip;
  });
  public isMobile: boolean = false;
  public isItemsRestricted: boolean = false;
  public match: Signal<ItemGroupMatch> = computed(() => {
    const attributeGroup = this.searchService.selectedAttributeGroups();
    const filters = this.searchService.filters();

    if (attributeGroup) {
      const group = Object.values(attributeGroup)[0];
      if (this.item.matches) {
        const match = this.item.matches.find(({ itemGroupId }) => itemGroupId === group?.id);

        if (match) {
          if (match.items && Object.keys(filters).length) {
            const filteredGroupItems = this.searchService.filterGroupItems(match.items);
            const vendors = this.searchService.getVendorsFromFilteredGroupItems(filteredGroupItems);

            return { ...match, vendors, count: filteredGroupItems.length };
          }

          return { ...match, vendors: [], count: 0 };
        }
      }
    }
    return { vendors: [] as any[], count: 0 } as any;
  });

  public vendorLogoKeys = computed(() => {
    const match = this.match();
    const item = this.item;
    if (match && match.vendors && item && item.vendor) {
      const vendorLogoKeys = match.vendors.filter(({ id }) => id !== item.vendor.id).map(({ key }) => key);
      return vendorLogoKeys;
    }

    return [];
  });

  public showGroupingBadge = computed(() => {
    const match = this.match();
    if (!match || !match.itemGroupName) {
      return false;
    }

    if (match.itemGroupName === 'Product') {
      return false;
    }

    return true;
  });

  public faSparkles = faSparkles;
  public featuresSub?: Subscription;

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    public featureService: FeatureService,
    private readonly eventBus: EventBusService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();

    //determining if the title was clamped or not based on the length of the title.
    //This length comparison is arbitrary based on how many characters would fit on 3 lines.
    this.itemTitleClamped = this.item.title.length > 105 ? true : false;

    this.showLabelControls = this.featureService.enabled('super-admin');
  }

  ngOnDestory(): void {
    this.featuresSub?.unsubscribe();
  }

  openItemSite(productUrl: any, vendor: any, itemId: number) {
    if (this.isLoading()) return;
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  async hideItem(item: any) {
    if (this.isLoading()) return;
    const hiddenItems = this.searchService.hiddenItems();
    const removedItemIndex = this.searchService.filteredSearchResults().findIndex((i: any) => i.id === item.id);
    hiddenItems.unshift({ ...item, removedItemIndex });
    //update the removedItemIndex for all items already hidden if needed
    for (let i = 1; i < hiddenItems.length; i++) {
      if (hiddenItems[i].removedItemIndex > removedItemIndex) {
        hiddenItems[i].removedItemIndex--;
      }
    }
    this.searchService.hiddenItems.set(hiddenItems);
    const updatedFilteredSearchResults = this.searchService.filteredSearchResults().filter((i: any) => i.id !== item.id);
    this.searchService.filteredSearchResults.set(updatedFilteredSearchResults);
    await this.searchService.updateMeta();
    this.itemsService.updateItem(item.id, true).subscribe();
    this.notificationService.hideItem({ ...item, removedItemIndex });
  }

  showDetails(item: any) {
    if (this.isItemsRestricted) {
      window.location.href = 'https://limblecmms.com/search-waitlist';
      return;
    }

    this.itemsService.trackClick(item.id, 'details').subscribe();

    if (this.isLoading()) return;

    const queryParams = { group: this.match()?.itemGroupValueId };
    this.searchService.selectedItem.set(item);
    this.searchService.selectedDetailsPageItem.set(item);
    this.searchService.startProgressBar();
    this.router.navigate(['/details', `${item.id}`], { queryParams });
  }

  setLabel(key: string, value: any) {
    if (this.item.id) {
      if (this.searchService.getItemLabel(this.item.id, key) == value) {
        value = null;
      }
      this.itemsService.updateLabel(this.item.id, key, value).subscribe();
      this.searchService.setItemLabel(this.item.id, key, value);
    }

    return false;
  }
}
