import { Component, computed, effect } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { SearchService } from '../_services/search.service';
import { Subscription } from 'rxjs';
import { TooltipDirective } from '@limblecmms/lim-ui';

@Component({
  selector: 'pre-search-hero',
  standalone: true,
  imports: [NgIf, NgFor, TooltipDirective],
  templateUrl: './pre-search-hero.component.html',
  styleUrl: './pre-search-hero.component.scss'
})
export class PreSearchHeroComponent {
  public preSearchDetails = computed(() => this.searchService.searchResultsMeta()?.preSearchDetails ?? null);
  public mainProductDetails = computed(() => this.preSearchDetails()?.mainProductDetails ?? null);
  public allProductFeatures = computed(() => this.preSearchDetails()?.allProductFeatures ?? null);
  public maxFeaturesToShow = 8;
  public searchResultsMetaSub?: Subscription;
  public featureList = computed(() => {
    const preSearchDetails = this.preSearchDetails();
    const { allProductFeatures, mainProductDetails } = preSearchDetails;

    const list = [
      { name: 'Typical Price', value: mainProductDetails?.typicalPrice },
      ...Object.entries(allProductFeatures).map(([key, value]) => ({ name: key, value }))
    ];

    return list.slice(0, this.maxFeaturesToShow);
  });

  constructor(public searchService: SearchService) {}
}
