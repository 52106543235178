import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'confirm-forgot-password',
  templateUrl: './confirm-forgot-password.component.html',
  styleUrls: ['./confirm-forgot-password.component.scss'],
  standalone: true,
  imports: []
})
export class ConfirmForgotPassword implements OnInit {
  bannerText: string = '';
  bannerMessage: string = '';
  router: Router = inject(Router);
  constructor() {}
  ngOnInit(): void {
    const currentRoute = this.router.url;
    if (currentRoute.includes('complete')) {
      this.bannerText = `Complete!`;
      this.bannerMessage = `You will receive an email containing the next steps`;
    }
  }
  backToLogin() {
    this.router.navigate(['/login'], { skipLocationChange: true });
    return;
  }
}
