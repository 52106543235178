<div class="pricing-plans-container">
  @if (plans().length) { @for (plan of plans(); track plan.id) {
  <pricing-plan-item [plan]="plan" [selectedPlan]="selectedPlan()" (selectPlan)="setSelectedPlanId($event)" />
  } } @else {
  <div class="pricing-plans-empty-list-message">
    {{ emptyListMessage() }}
  </div>
  }

  <div class="pricing-plans-actions">
    <div class="pricing-plans-actions-text">
      Want to modify your current plan or get more searches?<br> <a class="pricing-plans-actions-link" routerLink="#">Contact Support</a>
    </div>
    <button class="choose-plan" [class.disabled]="!canChoosePlan()" (click)="selectPlan()" [disabled]="!canChoosePlan()">
      {{ actionButtonText() }}
    </button>
  </div>
</div>
