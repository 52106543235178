import { Component, OnInit, signal, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';

import { LoginFormComponent } from './login-form/login-form.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [FormsModule, LoginFormComponent]
})
export class LoginComponent implements OnInit {
  public isMobile = signal(isMobile());
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly searchService = inject(SearchService);

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      if (this.searchService.query()) {
        this.router.navigate(['/searching'], {
          skipLocationChange: true,
          replaceUrl: true
        });
      } else {
        this.router.navigate(['']);
      }
    }
  }
}
