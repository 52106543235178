<div class="main-container">
  <div class="child-container">
    <div class="login-cover-image">
      <div class="image-text">Confidently find the cheapest part in a fraction of the time</div>
    </div>
  </div>
  <div class="child-container">
    <div class="form-container">
      <div class="waitlist-container">
        <h1 class="waitlist-banner">{{ bannerText }}</h1>
        <div class="signup-section">
          {{ bannerMessage }}
        </div>
        <button (click)="backToLogin()" class="login-button">Back to Login</button>
      </div>
    </div>
  </div>
</div>
