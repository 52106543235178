import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SearchService } from './search.service';
import { toObservable } from '@angular/core/rxjs-interop';

export const TitleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  searchService: SearchService = inject(SearchService)
) => {
  const query$ = toObservable(searchService.query);
  return query$.pipe(map((title: string): string => `Limble Search : ${title}`));
};
