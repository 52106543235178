import { Component, computed, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, distinctUntilChanged } from 'rxjs';

import { SearchService } from '../_services/search.service';
import { HeapService } from '../_services/heap.service';
import { isMobile } from '../_util/mobile.util';

import { PrimaryButtonComponent, IconComponent, ModalService } from '@limblecmms/lim-ui';
import { QueryQualityDropdownComponent } from '../query-quality-dropdown/query-quality-dropdown';
import { PartSearchModalComponent } from '../part-search-modal/part-search-modal.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService, UserAccessStatus } from '../_services/auth.service';
import { EventBusService } from '../_shared/event-bus.service';

@Component({
  selector: 'part-search-field',
  templateUrl: './part-search-field.component.html',
  styleUrls: ['./part-search-field.component.scss'],
  standalone: true,
  imports: [FormsModule, IconComponent, QueryQualityDropdownComponent]
})
export class PartSearchFieldComponent implements OnInit, OnDestroy {
  public showQueryQuality: boolean = false;
  public query: string = '';
  public isMobile: boolean = false;
  private readonly userAccessStatus = toSignal(this.authService.userAccessStatus$);

  private querySub?: Subscription;

  constructor(
    public searchService: SearchService,
    public router: Router,
    public heapService: HeapService,
    private readonly authService: AuthService,
    public modalService: ModalService,
    private readonly eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.query = this.searchService.query();
  }

  async search(): Promise<any> {
    if (this.userAccessStatus() !== UserAccessStatus.Full) {
      this.eventBusService.emit('SearchLimitModal.Show', null);
      return;
    }

    this.showQueryQuality = false;
    this.searchService.query.set(this.query || '');
    this.heapService.trackEvent('search', { query: this.query });

    if (this.query) {
      this.searchService.search(this.query);
      this.router.navigate(['/searching'], { skipLocationChange: true, replaceUrl: true });
    }
  }

  async openPartSearchModal(): Promise<any> {
    const activePartSearchModal = this.modalService.getActiveModal();
    if (activePartSearchModal !== null) {
      return activePartSearchModal;
    }
    const modalRef = this.modalService.open(PartSearchModalComponent);
    const modalResult = await modalRef.result;
    return modalResult;
  }

  async showQueryQualityDropdown() {
    if (this.isMobile) {
      this.showQueryQuality = false;
      const modalRef = await this.openPartSearchModal();
      if (modalRef === false) {
        return;
      }
      await this.searchService.query.set(modalRef);
      await this.search();
    } else {
      this.showQueryQuality = true;
    }
  }

  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
  }
}
