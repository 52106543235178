import { Component, inject, signal, output, computed, input } from '@angular/core';
import { PricingPlanItemComponent } from '../pricing-plan-item/pricing-plan-item.component';
import { SubscriptionService } from '../../../_services/subscription.service';
import { SubscriptionTier } from '../../../_types/user';

@Component({
  selector: 'pricing-plans',
  standalone: true,
  imports: [PricingPlanItemComponent],
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss']
})
export class PricingPlansComponent {
  private readonly subscriptionService = inject(SubscriptionService);

  selectedPlan = signal<SubscriptionTier | null>(null);
  confirmPlan = output<SubscriptionTier>();
  canChoosePlan = computed(() => {
    const selectedPlan = this.selectedPlan();
    if (selectedPlan) {
      return true;
    }
    return false;
  });

  plans = input.required<SubscriptionTier[]>();
  actionButtonText = input.required<string>();
  emptyListMessage = input<string>();

  setSelectedPlanId(plan: SubscriptionTier) {
    this.selectedPlan.set(plan);
  }

  selectPlan() {
    const selectedPlan = this.selectedPlan();
    if (selectedPlan) {
      this.confirmPlan.emit(selectedPlan);
    }
  }
}
