<div class="container">
  <div class="full-page-banner" *ngIf="showSignupBanner">
    <div class="banner-content">
      <fa-icon [icon]="faLockKeyhole"></fa-icon>
      <div class="msg">
        Access all results now.
        <a (click)="clickSignupBanner()" class="cta">Sign Up for Free</a>
      </div>
    </div>
    <div class="close" (click)="closeSignupBanner()">&times;</div>
  </div>

  <ng-container *ngIf="!isMobile">
    <div
      class="loading-bar-container"
      [class]="{ fadeOut: searchService.percentComplete() >= 100, hidden: !showLoadingBar() }"
    >
      <div class="loading-bar" [ngStyle]="{ width: searchService.percentComplete() + '%' }"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div
      *ngIf="!searchService.searchComplete()"
      class="loading-bar-container"
      [class]="{ fadeOut: searchService.percentComplete() >= 100, hidden: !showLoadingBar() }"
    >
      <div class="loading-bar" [ngStyle]="{ width: searchService.percentComplete() + '%' }"></div>
    </div>
    <div
      class="search-settings"
      [ngClass]="searchService.searchModalState() || showSortFilter ? 'hideSearchSettings' : 'showSearchSettings'"
    >
      <part-search-field></part-search-field>
      <div class="sort-location">
        <div class="sort-header">
          <fa-icon [icon]="faFilterList" size="sm" style="color: #5083d5"></fa-icon>
          <div (click)="toggleSortAndFilter()">Sort & Filter</div>
        </div>
        <delivery-location></delivery-location>
      </div>
    </div>
  </ng-container>
  <section>
    <ng-container *ngIf="!isMobile">
      <div>
        <aside class="filters" *ngIf="showSmartGroupings()">
          <div class="header">
            <fa-icon [icon]="faSparkles" [style.color]="'#0090FF'" />
            <h1>Smart Grouping</h1>
          </div>
          <div class="filter">
            <div class="group-header">Group results by selected attributes.</div>
            <div *ngFor="let attributeGroup of attributeGroups()" class="vendor-filter">
              <lim-ui-radio-button
                [label]="attributeGroup.name"
                [groupID]="'smartGroupings'"
                [checked]="!!searchService.selectedAttributeGroups()?.[attributeGroup.id]"
                (clickRadioButton)="updateSelectedAttributGroups(attributeGroup)"
              />
            </div>
          </div>
        </aside>
        <aside class="filters">
          <h1 class="filter-by">Filter By:</h1>
          <div class="filter">
            <div class="filter-header">
              <h1>Vendors</h1>
              <a class="select-all" (click)="selectAllVendors()" [class]="{ disabled: allVendorsSelected() }">
                Select All
              </a>
            </div>

            <div class="slow-vendor-container" *ngIf="searchService.isPreviewing() && slowVendorCounts().length > 0">
              <p *ngIf="!searchService.searchComplete()">Some vendors are taking longer to load.</p>
              <div
                *ngFor="let slowVendor of slowVendorCounts()"
                class="vendor-filter"
                [class]="{ loading: slowVendor.status === 'loading' }"
              >
                <lim-ui-checkbox
                  [model]="selectedVendors()[slowVendor.vendorId]"
                  (modelChange)="updateSelectedVendors(slowVendor.vendorId)"
                  [label]="slowVendor.vendorName"
                  [disabled]="slowVendor.status === 'loading'"
                  *ngIf="!searchService.isItemsRestricted()"
                />
                <lim-ui-checkbox
                  [model]="true"
                  [disabled]="true"
                  (click)="$event.preventDefault(); clickSignupBanner()"
                  [label]="slowVendor.vendorName"
                  *ngIf="searchService.isItemsRestricted()"
                />
                <div class="vendor-item-count">
                  <img
                    *ngIf="slowVendor.status === 'loading'"
                    src="/assets/images/loading-spinner-green.png"
                    class="loading-spinner"
                    alt="Loading Spinner"
                  />
                  <div *ngIf="slowVendor.status !== 'loading'">
                    <span class="count">{{ slowVendor.itemCount }}</span>
                    <a class="only" (click)="onlyVendor(slowVendor.vendorId)">Only</a>
                  </div>
                </div>
              </div>
            </div>

            <div *ngFor="let vendor of fastVendorCounts()" class="vendor-filter">
              <lim-ui-checkbox
                [model]="selectedVendors()[vendor.vendorId]"
                (modelChange)="updateSelectedVendors(vendor.vendorId)"
                [label]="vendor.vendorName"
                *ngIf="!searchService.isItemsRestricted()"
              />
              <lim-ui-checkbox
                [model]="true"
                [disabled]="true"
                (click)="$event.preventDefault(); clickSignupBanner()"
                [label]="vendor.vendorName"
                *ngIf="searchService.isItemsRestricted()"
              />
              <div class="vendor-item-count">
                <span class="count">{{ vendor.itemCount }}</span>
                <a class="only" (click)="onlyVendor(vendor.vendorId)">Only</a>
              </div>
            </div>

            <div class="skeleton-loaders" *ngIf="!searchService.isPreviewing()">
              <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
              <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
              <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
            </div>

            <div
              class="show-hidden-vendors"
              *ngIf="hiddenVendorCounts().length > 0 && searchService.isPreviewing()"
              (click)="showHiddenVendors = !showHiddenVendors"
            >
              <span>Show hidden vendors</span>
              <fa-icon [icon]="showHiddenVendors ? faChevronUp : faChevronDown" size="sm"></fa-icon>
            </div>

            <div class="hidden-vendors-container" *ngIf="showHiddenVendors">
              <div *ngFor="let vendor of hiddenVendorCounts()" class="vendor-filter">
                <lim-ui-checkbox
                  [model]="selectedVendors()[vendor.vendorId]"
                  (modelChange)="updateSelectedVendors(vendor.vendorId)"
                  [label]="vendor.vendorName"
                  *ngIf="!searchService.isItemsRestricted()"
                />
                <lim-ui-checkbox
                  [disabled]="true"
                  (click)="$event.preventDefault(); clickSignupBanner()"
                  [label]="vendor.vendorName"
                  *ngIf="searchService.isItemsRestricted()"
                />
                <div class="vendor-item-count">
                  <img
                    *ngIf="vendor.status === 'loading'"
                    src="/assets/images/loading-spinner-green.png"
                    class="loading-spinner"
                    alt="Loading Spinner"
                  />
                  <div *ngIf="vendor.status !== 'loading'">
                    <span class="count">{{ vendor.itemCount }}</span>
                    <a class="only" (click)="onlyVendor(vendor.vendorId)">Only</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="filter">
            <div class="filter-header">
              <h1>Price</h1>
            </div>
            <div class="price-input">
              <div>
                $
                <input
                  type="number"
                  [(ngModel)]="minPrice"
                  placeholder="Min"
                  min="0"
                  (input)="forceInteger($event)"
                  (ngModelChange)="filterByPrice()"
                />
              </div>
              <span>to</span>
              <div>
                $
                <input
                  type="number"
                  [(ngModel)]="maxPrice"
                  placeholder="Max"
                  min="0"
                  (input)="forceInteger($event)"
                  (ngModelChange)="filterByPrice()"
                />
              </div>
            </div>
            <div class="price-range">
              <span *ngIf="globalMinPrice() != null && searchService.isPreviewing()">
                ${{ Math.round(globalMinPrice()) }}
              </span>
              <skeleton-loading-bar-animation [hidden]="searchService.isPreviewing()"></skeleton-loading-bar-animation>
              <span *ngIf="globalMaxPrice() != null && searchService.isPreviewing()">
                ${{ Math.round(globalMaxPrice()) }}
              </span>
              <skeleton-loading-bar-animation [hidden]="searchService.isPreviewing()"></skeleton-loading-bar-animation>
            </div>
          </div>
        </aside>
      </div>
    </ng-container>

    <part-search-results-grouped></part-search-results-grouped>
  </section>
  <div *ngIf="isMobile && showSortFilter" [ngClass]="{ backdrop: showSortFilter }" (click)="toggleSortAndFilter()"></div>
  <div *ngIf="isMobile && showSortFilter" class="sort-filter-overlay" [ngClass]="{ 'show-overlay': showSortFilter }">
    <div class="sort-filter-header">
      <h3>Sort & Filter</h3>
      <lim-ui-icon icon="xmark" (click)="toggleSortAndFilter()"></lim-ui-icon>
    </div>
    <div class="overlay-content">
      <div class="responsive-sort">
        <lim-ui-dropdown [hidden]="!searchService.isPreviewing()">
          <div class="sort-dropdown" button>
            <div class="sort-dropdown-header">
              <div class="sort-by">Sort By:</div>
              <div class="sort-title">{{ getSortByLabel() }}</div>
            </div>
            <lim-ui-icon icon="angleDown" size="sm"></lim-ui-icon>
          </div>
          <ng-container menu>
            <lim-ui-dropdown-text-item (click)="sortResults('relevance')" [displayString]="'Relevance'" />
            <lim-ui-dropdown-text-item (click)="sortResults('price_per:asc')" [displayString]="'Price: Low to High'" />
            <lim-ui-dropdown-text-item (click)="sortResults('price_per:desc')" [displayString]="'Price: High to Low'" />
          </ng-container>
        </lim-ui-dropdown>
        <skeleton-sort-animation [hidden]="searchService.isPreviewing()"></skeleton-sort-animation>
      </div>
      <div class="line-break"></div>
      <div class="responsive-filters">
        <div class="header">
          <fa-icon [icon]="faSparkles" size="sm" [style.color]="'#0090FF'" />
          <h1 class="filter-by">Smart Grouping</h1>
        </div>
        <div class="filter-responsive">
          <div class="group-header">Group results by selected attributes.</div>
          <div *ngFor="let attributeGroup of attributeGroups()" class="vendor-filter">
            <lim-ui-radio-button
              [label]="attributeGroup.name"
              [groupID]="'smartGroupings'"
              [checked]="!!searchService.selectedAttributeGroups()?.[attributeGroup.id]"
              (clickRadioButton)="updateSelectedAttributGroups(attributeGroup)"
            />
          </div>
        </div>
      </div>
      <div class="line-break"></div>
      <div class="responsive-filters">
        <h1 class="filter-by">Filter By:</h1>
        <div class="vendors-filter-section">
          <div class="filter-header">
            <h1>Vendors</h1>
            <a class="select-all" (click)="selectAllVendors()" *ngIf="!allVendorsSelected()"> Select All </a>
            <a class="select-all" (click)="deselectAllVendors()" *ngIf="allVendorsSelected()"> Deselect All </a>
          </div>

          <div class="slow-vendor-container" *ngIf="searchService.isPreviewing() && slowVendorCounts.length > 0">
            <p *ngIf="!searchService.searchComplete()">Some vendors are taking longer to load.</p>
            <div
              *ngFor="let slowVendor of slowVendorCounts()"
              class="vendor-filter"
              [class]="{ loading: slowVendor.status === 'loading' }"
            >
              <lim-ui-checkbox
                [model]="selectedVendors()[slowVendor.vendorId]"
                (modelChange)="updateSelectedVendors(slowVendor.vendorId)"
                [label]="slowVendor.vendorName"
                [disabled]="slowVendor.status === 'loading'"
              />
              <div class="vendor-item-count">
                <img
                  *ngIf="slowVendor.status === 'loading'"
                  src="/assets/images/loading-spinner-green.png"
                  class="loading-spinner"
                  alt="Loading Spinner"
                />
                <div *ngIf="slowVendor.status !== 'loading'">
                  <span class="count">{{ slowVendor.itemCount }}</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngFor="let vendor of fastVendorCounts()" class="vendor-filter">
            <lim-ui-checkbox
              [model]="selectedVendors()[vendor.vendorId]"
              (modelChange)="updateSelectedVendors(vendor.vendorId)"
              [label]="vendor.vendorName"
            />
            <div *ngIf="vendor.status !== 'loading'">
              <span class="count">{{ vendor.itemCount }}</span>
            </div>
          </div>

          <div class="skeleton-loaders" *ngIf="!searchService.isPreviewing()">
            <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
            <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
            <skeleton-loading-bar-animation></skeleton-loading-bar-animation>
          </div>

          <div
            class="show-hidden-vendors"
            *ngIf="hiddenVendorCounts().length > 0 && searchService.isPreviewing()"
            (click)="showHiddenVendors = !showHiddenVendors"
          >
            <span>Show hidden vendors</span>
            <fa-icon [icon]="showHiddenVendors ? faChevronUp : faChevronDown" size="sm"></fa-icon>
          </div>

          <div class="hidden-vendors-container" *ngIf="showHiddenVendors">
            <div *ngFor="let vendor of hiddenVendorCounts()" class="vendor-filter">
              <lim-ui-checkbox
                [model]="selectedVendors()[vendor.vendorId]"
                (modelChange)="updateSelectedVendors(vendor.vendorId)"
                [label]="vendor.vendorName"
              />
              <div class="vendor-item-count">
                <img
                  *ngIf="vendor.status === 'loading'"
                  src="/assets/images/loading-spinner-green.png"
                  class="loading-spinner"
                  alt="Loading Spinner"
                />
                <div *ngIf="vendor.status !== 'loading'">
                  <span class="count">{{ vendor.itemCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="filter">
          <div class="filter-header">
            <h1>Price</h1>
          </div>
          <div class="price-input">
            <div>
              $
              <input
                type="number"
                [(ngModel)]="minPrice"
                placeholder="Min"
                min="0"
                (input)="forceInteger($event)"
                (ngModelChange)="filterByPrice()"
              />
            </div>
            <span>to</span>
            <div>
              $
              <input
                type="number"
                [(ngModel)]="maxPrice"
                placeholder="Max"
                min="0"
                (input)="forceInteger($event)"
                (ngModelChange)="filterByPrice()"
              />
            </div>
          </div>
          <div class="price-range">
            <span *ngIf="globalMinPrice != null && searchService.isPreviewing()"> ${{ Math.round(globalMinPrice) }} </span>
            <skeleton-loading-bar-animation [hidden]="searchService.isPreviewing()"></skeleton-loading-bar-animation>
            <span *ngIf="globalMaxPrice != null && searchService.isPreviewing()"> ${{ Math.round(globalMaxPrice) }} </span>
            <skeleton-loading-bar-animation [hidden]="searchService.isPreviewing()"></skeleton-loading-bar-animation>
          </div>
        </div>
      </div>
    </div>
    <div class="done"><button (click)="toggleSortAndFilter()">Done</button></div>
  </div>
</div>
