import { Component, inject } from '@angular/core';
import { IconComponent, PopoverDirective } from '@limblecmms/lim-ui';
import { AuthService } from 'src/app/_services/auth.service';

interface App {
  id: number;
  name: string;
  description: string;
  link: string;
  target: string;
  new?: boolean;
}

@Component({
  selector: 'app-switcher',
  standalone: true,
  imports: [IconComponent, PopoverDirective],
  templateUrl: './app-switcher.component.html',
  styleUrl: './app-switcher.component.scss'
})
export class AppSwitcherComponent {
  private readonly authService = inject(AuthService);

  public showCmmsLogin = this.authService.showCmmsLogin;

  protected apps: App[] = [
    {
      id: 1,
      name: 'Limble CMMS',
      description: 'CMMS & Asset management platform',
      link: 'https://app.limblecmms.com/dashboard',
      target: '_blank'
    },
    {
      id: 2,
      name: 'Limble Search',
      description: 'Price checking tool for all your parts',
      link: '/',
      target: '_blank',
      new: true
    }
  ];
}
