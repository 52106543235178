import { Component, effect, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf, NgFor, NgClass, CurrencyPipe } from '@angular/common';

import { ItemsService } from '../_services/items.service';
import { SearchService } from '../_services/search.service';
import { NotificationService } from '../_services/notification.service';
import { FeatureService } from '../_services/feature.service';
import { isMobile } from '../_util/mobile.util';

import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';

@Component({
  selector: 'vendor-grouped-items',
  templateUrl: './vendor-grouped-items.component.html',
  styleUrls: ['./vendor-grouped-items.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, CurrencyPipe, NgClass, ShippingDetailsComponent]
})
export class VendorGroupedComponent implements OnInit {
  public seeAllToggle: boolean = false;
  public currentSelectedItem: any = {};
  public currentSelectedVendor: number = 0;
  public unhiddenVendorItems: number = 0;
  public showLabelControls: boolean = false;
  public isMobile: boolean = false;

  @Input()
  set selectedItem(item: any) {
    this.currentSelectedItem = item;
  }

  @Input()
  set showOptions(showAll: boolean) {
    this.seeAllToggle = showAll;
  }

  @Input() vendorGroupedItems: any;
  @Input() set selectedVendorId(vendorId: number) {
    this.currentSelectedVendor = vendorId;
  }

  @Output() onItemSelect = new EventEmitter();
  @Output() unhideItem = new EventEmitter();

  constructor(
    public searchService: SearchService,
    public notificationService: NotificationService,
    public itemsService: ItemsService,
    public featureService: FeatureService
  ) {
    this.showLabelControls = this.featureService.enabled('super-admin');

    this.isMobile = isMobile();

    effect(() => {
      const item = this.searchService.selectedDetailsPageItem();
      this.currentSelectedItem = item;
    });
  }

  ngOnInit(): void {
    this.isMobile = isMobile();

    // If seeAll was overridden (toggled true) from parent component, don't change it
    if (this.seeAllToggle) {
      return;
    }

    // this.unhiddenVendorItems = this.vendorGroupedItems.filter((item) => !item.user_hidden).length;
    if (this.vendorGroupedItems.length >= 2) {
      this.seeAllToggle = false;
    } else {
      this.seeAllToggle = true;
    }

    //if currentSelectedItem in this vendorGroupedItems other than position 0 and the vendorGroupedItems length >=2 set seeAllToggle to true
    if (
      this.currentSelectedItem &&
      this.vendorGroupedItems.map(({ id }) => id).indexOf(this.currentSelectedItem.id) > 0 &&
      this.vendorGroupedItems.length >= 2
    ) {
      this.seeAllToggle = true;
    } else if (this.vendorGroupedItems.length === 1) {
      this.seeAllToggle = true;
    }
  }

  selectItem(e: Event, item: any) {
    if (item.user_hidden) {
      item.user_hidden = false;
      this.unhideItem.emit(item);
    }
    this.currentSelectedItem = item;
    this.onItemSelect.emit(item);
  }

  toggleSeeAll() {
    this.seeAllToggle = !this.seeAllToggle;
    window.dispatchEvent(new CustomEvent('itemView'));
  }

  setLabel(itemId: any, key: string, value: any) {
    if (this.searchService.getItemLabel(itemId, key) == value) {
      value = null;
    }
    this.itemsService.updateLabel(itemId, key, value).subscribe();
    this.searchService.setItemLabel(itemId, key, value);

    return false;
  }
}
