<div
  class="app-switcher-button"
  [limUiPopover]="appSwitcherPopover"
  [limUiPopoverPlacement]="'bottom-start'"
  [limUiPopoverOffset]="12"
>
  <lim-ui-icon icon="gridSolid" iconSize="medium" />
</div>

<ng-template #appSwitcherPopover>
  <div class="app-switcher-popover-body">
    <div class="body-header">
      <h4 class="body-title">Your Apps</h4>
    </div>
    <div class="apps-list">
      @for (app of apps; track app.id) {
      <a href="{{ app.link }}" target="{{ app.target }}">
        <div class="app">
          <div class="content">
            <div class="app-title-container">
              <h5 class="app-title">{{ app.name }}</h5>
              @if (app.new) {
              <div class="new-badge">
                <lim-ui-icon [iconSize]="'extra-small'" icon="bullhorn" />
                <span>New</span>
              </div>
              }
            </div>
            <div class="app-description">
              {{ app.description }}
            </div>
          </div>
        </div>
      </a>
      }
    </div>
  </div>
</ng-template>
