import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy, computed } from '@angular/core';
import { NgIf, CurrencyPipe, NgFor } from '@angular/common';
import { Subscription, combineLatest } from 'rxjs';

import { IconComponent } from '@limblecmms/lim-ui';
import { ShippingDetailsComponent } from '../shipping-details/shipping-details.component';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { ItemsService } from '../_services/items.service';
import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';
import { BadgeComponent } from '../badge/badge.component';
import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';
import { FeatureService } from '../_services/feature.service';

@Component({
  selector: 'item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
  standalone: true,
  imports: [ShippingDetailsComponent, IconComponent, CurrencyPipe, NgIf, NgFor, BadgeComponent]
})
export class ItemDetailsComponent implements OnInit, OnChanges, OnDestroy {
  public collapsed: boolean = false;
  public itemAttributes: any = [];
  public moreProductDetails: boolean = false;
  public descriptionClamped: boolean = false;
  public isMobile: boolean = false;
  public badgeLabel = computed(() => {
    const selectedAttributeGroup = this.searchService.selectedAttributeGroups();
    if (selectedAttributeGroup) {
      const group = Object.values(selectedAttributeGroup)[0];
      if (this.isMobile) {
        return `${this._selectedItemMatch ? this._selectedItemMatch.itemGroupValue : 'Not Found'}`;
      } else {
        return `${group.name} \u2022 ${this._selectedItemMatch ? this._selectedItemMatch.itemGroupValue : 'Not Found'}`;
      }
    }
    return '';
  });
  public showGroupingBadge = computed(() => {
    const selectedAttributeGroup = this.searchService.selectedAttributeGroups();
    if (!selectedAttributeGroup) {
      return false;
    }

    const group = Object.values(selectedAttributeGroup)[0];
    if (group.name === 'Product') {
      return false;
    }

    return true;
  });
  public faSparkles = faSparkles;
  public selectedAttributeGroupIsSelected = computed(() => Boolean(this.searchService.selectedAttributeGroups()));

  public mainGroupedItemId = computed(() => this.searchService.selectedItem()?.id);

  private _selectedItemMatch: ItemGroupMatch | undefined;

  public featureServiceSub?: Subscription;

  @Input() selectedItem;

  @Input()
  set selectedItemMatch(match: ItemGroupMatch | undefined) {
    this._selectedItemMatch = match;
  }
  get selectedItemMatch() {
    return this._selectedItemMatch;
  }

  @Output() itemDetailsChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public searchService: SearchService,
    public itemsService: ItemsService,
    public featureService: FeatureService
  ) {}

  ngOnInit() {
    this.isMobile = isMobile();
    this.moreItemAttributes();
    this.descriptionClamped = this.selectedItem?.description?.length > 800 ? true : false;
  }

  ngOnChanges(changes) {
    this.moreItemAttributes();
    if (!this.moreProductDetails) {
      this.collapsed = false;
    }
    this.descriptionClamped = this.selectedItem?.description?.length > 800 ? true : false;
  }

  ngOnDestroy(): void {
    this.featureServiceSub?.unsubscribe();
  }

  openItemSite(productUrl: any, vendor: any, itemId: number) {
    this.searchService.openItemSite(productUrl, vendor, itemId);
  }

  showMoreProductDetails() {
    this.collapsed = !this.collapsed;
    const heroSection = document.getElementById('hero-section');
    if (!this.collapsed) {
      if (heroSection) {
        heroSection.style.position = 'sticky';
      }
      return;
    }
    if (heroSection) {
      heroSection.style.position = 'static';
      heroSection.scrollIntoView();
    }
  }

  hideItem(e: Event, item: any) {
    e.stopPropagation();
    // TODO: fix this hide item behavior
    // this.searchService.hideHeroItem(item);
  }

  moreItemAttributes() {
    this.itemAttributes = [];
    const neededAttributeKeys = {
      manufacturerPartNumber: 'Part Number',
      brandName: 'Brand',
      upc: 'UPC',
      sku: 'SKU',
      unspc: 'UNSPC'
    };
    const neededAttributes = Object.keys(neededAttributeKeys);
    for (let attribute in this.selectedItem) {
      if (
        neededAttributes.includes(attribute) &&
        this.selectedItem[attribute] !== null &&
        this.selectedItem[attribute] !== ''
      ) {
        this.itemAttributes.push({
          attributeName: neededAttributeKeys[attribute],
          attributeValue: this.selectedItem[attribute]
        });
      }
    }

    const extractedAttributes = this.selectedItem.attributes;
    if (extractedAttributes) {
      extractedAttributes
        .filter(({ attributeName }) => attributeName !== 'Part Number')
        .forEach(({ attributeName, attributeValue }) => {
          this.itemAttributes.push({
            attributeName,
            attributeValue
          });
        });
    }

    if (this.itemAttributes.length >= 2) {
      this.moreProductDetails = true;
    } else {
      this.moreProductDetails = false;
    }
  }
}
