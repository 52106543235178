<div class="pre-search-hero" *ngIf="preSearchDetails()">
  <div class="hero-image">
    <img [src]="mainProductDetails().productImage" alt="Hero Image" />
  </div>
  <div class="hero-content">
    <div class="title">{{ mainProductDetails().title }}</div>
    <div class="line-break"></div>
    <div class="all-features">
      <div class="feature-list">
        <div class="feature" *ngFor="let feature of featureList().slice(0, 4)">
          <span>{{ feature.name }}</span>
          <ng-container *ngIf="feature.value.length > 15; else featureValueCol1"
            ><span [limUiTooltip]="feature.value" [placement]="'bottom'">{{ feature.value }}</span></ng-container
          >
          <ng-template #featureValueCol1
            ><span>{{ feature.value }}</span></ng-template
          >
        </div>
      </div>
      <div class="feature-list">
        <div class="feature" *ngFor="let feature of featureList().slice(4, 8)">
          <span>{{ feature.name }}</span>
          <ng-container *ngIf="feature.value.length > 15; else featureValueCol2"
            ><span [limUiTooltip]="feature.value" [placement]="'bottom'">{{ feature.value }}</span></ng-container
          >
          <ng-template #featureValueCol2
            ><span>{{ feature.value }}</span></ng-template
          >
        </div>
      </div>
    </div>
  </div>
</div>
