import { Component, OnInit, OnDestroy, inject, signal, computed } from '@angular/core';
import { NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../_services/auth.service';
import { NotificationService } from '../../_services/notification.service';
import { SearchService } from '../../_services/search.service';
import { isMobile } from '../../_util/mobile.util';

import { Aliases, IconComponent } from '@limblecmms/lim-ui';
import { LogoAnimation } from '../../animations/logo-animation/logo-animation.component';

import { CONFIG } from '../../../environments/environment';
import { toSignal } from '@angular/core/rxjs-interop';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, IconComponent, LogoAnimation, ForgotPasswordFormComponent]
})
export class LoginFormComponent implements OnInit {
  loginForm: any = {
    email: null,
    password: null
  };

  showForgotPasswordForm = signal(false);
  showPassword = signal(false);

  user: any = {};

  protected eyeSlashIcon: Aliases = 'eyeSlashRegular';
  protected eyeIcon: Aliases = 'eyeRegular';

  public loading = signal(false);
  public isMobile: boolean = false;

  private authService = inject(AuthService);
  private http = inject(HttpClient);
  private router = inject(Router);
  private searchService = inject(SearchService);
  private notificationService = inject(NotificationService);

  private _error = toSignal(this.authService.error$);

  public filteredError = computed(() => {
    if (this._error() !== 'Authorization required') {
      return this._error();
    }

    return null;
  });

  public error = toSignal(this.authService.error$);
  public query = this.searchService.query();
  public allowCmmsLogin = this.authService.allowCmmsLogin;
  public showCmmsLogin = this.authService.showCmmsLogin;

  ngOnInit(): void {
    this.checkHealth();
    this.isMobile = isMobile();
  }

  async checkHealth(): Promise<any> {
    if (
      localStorage.getItem('healthCheck') &&
      Date.now() - parseInt(localStorage.getItem('healthCheck') || '') < 1000 * 60 * 5
    ) {
      return;
    } else {
      this.http.get(CONFIG.API_URL + 'healthPing').subscribe(
        (response) => console.log('Lim Search Online:', response),
        (error) => console.error('Health check failed:', error)
      );
      localStorage.setItem('healthCheck', Date.now().toString());
    }
  }

  login(): void {
    this.loading.set(true);
    const { email, password } = this.loginForm;
    this.authService.login(email, password).subscribe({
      next: () => {
        this.loading.set(false);
        if (this.searchService.query()) {
          this.router.navigate(['/searching'], {
            skipLocationChange: true,
            replaceUrl: true
          });
        } else {
          this.router.navigate(['']);
        }
      },
      error: (err) => {
        this.notificationService.error(err.error.message);
        this.loading.set(false);
      }
    });
  }

  loginCMMS() {
    return (window.location.href = 'https://auth.limblecmms.com/');
  }

  public togglePasswordVisibility() {
    this.showPassword.update((showPassword) => !showPassword);
    const password = document.querySelector('#password');

    //  toggle the type attribute
    const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
    password?.setAttribute('type', type);
  }
}
