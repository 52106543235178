<section class="best-match-container">
  <ng-container *ngIf="!isMobile; else responsiveBestMatch">
    <!--making sure groupedSearchResults is done grouping to show the best match after the search is completed-->
    <ng-container *ngIf="showBestMatchContainer()">
      <custom-lim-ui-panel [useCustomTitle]="true" [collapsable]="true" [collapsed]="false" [noBorder]="true">
        <div panelCustomTitle class="badge-container">
          <search-ui-badge *ngIf="showGroupingBadge()" [icon]="faSparkles" [label]="badgeLabel()" />
          <search-ui-badge label="Best Match" color="#E9F5EC" />
        </div>
        <ng-container *ngIf="preSearchPresent(); else nonPreSearchBestMatch"
          ><pre-search-best-match
            [groupedItems]="preSearchBestMatchItems()"
            [match]="match()"
            [vendorLogoKeys]="vendorLogoKeys()"
            [vendorTooltip]="vendorTooltip()"
            (openDetailsPage)="showDetails($event)"
            (labelSelection)="setLabel($event.itemId, $event.label, $event.value)"
          ></pre-search-best-match
        ></ng-container>
        <ng-template #nonPreSearchBestMatch>
          <div class="hero-section" (click)="showDetails(heroItem())" *ngIf="heroItem()">
            <div class="item-image-container">
              <img
                [src]="
                  heroItem().mainImageUrl && heroItem().mainImageUrl.length === 0
                    ? '/assets/images/placeholder-img.png'
                    : heroItem().mainImageUrl
                "
                class="item-image"
                alt="Item image"
                onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
              />
              <div class="image-lock" *ngIf="heroItem().isLocked">
                <fa-icon [icon]="lockKeyhole"></fa-icon>
              </div>
            </div>

            <div class="details">
              <div class="logo-container">
                <div class="vendor-logo">
                  <img [src]="heroItem().vendor.logoUrl" alt="Vendor logo" *ngIf="!heroItem().isLocked" />
                </div>
                <div
                  class="other-vendors"
                  *ngIf="
                    !heroItem().isLocked &&
                    match() &&
                    match()?.vendors &&
                    match().vendors.length > 0 &&
                    match().vendors.length <= 3
                  "
                  [limUiTooltip]="vendorTooltip()"
                >
                  <img
                    *ngFor="let vendorKey of vendorLogoKeys()"
                    [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                    class="vendor-icon"
                  />
                </div>
                <div
                  class="other-vendors"
                  *ngIf="!bestMatch().isLocked && match() && match().vendors && match().vendors.length > 3"
                  [limUiTooltip]="vendorTooltip()"
                >
                  <img
                    *ngFor="let vendorKey of vendorLogoKeys().slice(0, 2)"
                    [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                    class="vendor-icon"
                  />
                  <div class="more-vendors">{{ match().vendors.length - 2 }}+</div>
                </div>
              </div>
              <div class="title" *ngIf="!heroItem().isLocked">{{ heroItem().title }}</div>
              <div class="title" *ngIf="heroItem().isLocked">
                <div class="title-locked">&nbsp;</div>
              </div>
              <shipping-details [item]="heroItem()"></shipping-details>
            </div>

            <div class="actions">
              <div class="price-container">
                <div class="item-price">
                  {{ heroItem().price | currency }}
                </div>
                <div class="secondary-price" *ngIf="heroItem().price != heroItem().pricePer">
                  {{ heroItem().pricePer | currency }}
                </div>
              </div>

              <div class="visit-site">
                <div class="label-container" *ngIf="showLabelControls">
                  <button
                    (click)="setLabel(heroItem().id, 'vote', 1); $event.stopPropagation()"
                    [class]="{ selected: heroItem().labels['vote'] == 1 }"
                  >
                    <img src="assets/images/thumbs-up-icon.svg" />
                  </button>
                  <button
                    (click)="setLabel(heroItem().id, 'vote', -1); $event.stopPropagation()"
                    [class]="{ selected: heroItem().labels['vote'] == -1 }"
                  >
                    <img src="assets/images/thumbs-down-icon.svg" />
                  </button>
                </div>
                <button
                  (click)="openItemSite(heroItem().productUrl, heroItem().vendor, heroItem().id); $event.stopPropagation()"
                  class="main-page-btn"
                  *ngIf="!heroItem().isLocked"
                >
                  <span class="visit-site-badge">Visit Site</span>
                </button>
                <button class="btn-locked" *ngIf="heroItem().isLocked">
                  Unlock for Free
                  <fa-icon [icon]="lockKeyhole" size="lg"></fa-icon>
                </button>
                <div class="comparison" *ngIf="!searchService.isItemsRestricted() && match() && match().count > 0">
                  <span *ngIf="match().vendors.length == 1 && match().count > 1">
                    Compare {{ match().count }} option{{ match().count > 1 ? 's' : '' }}
                  </span>
                  <span *ngIf="match().vendors.length > 1"> Compare {{ match().vendors.length }} vendors </span>
                </div>
              </div>
            </div>
          </div>
          <div class="line-break" *ngIf="groupedItems().length > 1"></div>
          <div class="buying-options" *ngIf="groupedItems().length > 1">
            <div class="buying-header-container">
              <span class="buying-header">Top Buying Options</span>
            </div>
            <ul class="items">
              <li
                *ngFor="let item of groupedItems(); index as i"
                class="item-container"
                [class.selected]="item.id == heroItem().id"
                (click)="selectItem(item)"
              >
                <div class="item" [attr.data-item-id]="item?.id">
                  <div class="vendor-logo">
                    <img [src]="item?.vendor?.logoUrl" alt="Vendor Logo" *ngIf="!item.isLocked" />
                    <div class="vendor-locked" *ngIf="item.isLocked" [class]="{ full: i == 0 }">
                      <fa-icon [icon]="lockKeyhole" size="lg"></fa-icon>
                      <span *ngIf="i == 0">Lowest Price</span>
                    </div>
                  </div>
                  <div [ngClass]="item?.title?.length <= 50 ? 'single-line-title' : 'title'" *ngIf="!item.isLocked">
                    {{ item.title }}
                  </div>
                  <div class="title" *ngIf="item.isLocked">
                    <div class="title-locked">&nbsp;</div>
                  </div>
                  <div [ngClass]="'align-single-line-shipping'">
                    <shipping-details [item]="item"></shipping-details>
                  </div>
                  <div class="label-container" *ngIf="showLabelControls">
                    <button
                      (click)="setLabel(item.id, 'vote', 1); $event.stopPropagation()"
                      [class]="{ selected: item.labels['vote'] == 1 }"
                    >
                      <img src="assets/images/thumbs-up-icon.svg" />
                    </button>
                    <button
                      (click)="setLabel(item.id, 'vote', -1); $event.stopPropagation()"
                      [class]="{ selected: item.labels['vote'] == -1 }"
                    >
                      <img src="assets/images/thumbs-down-icon.svg" />
                    </button>
                  </div>
                  <div class="detail-right">
                    <div class="price">
                      <div class="total">
                        {{ item?.price | currency }}
                      </div>
                      <div class="per" *ngIf="item?.pricePer !== item?.price">({{ item.pricePer | currency }}/count)</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="more-options">
              <div class="all-vendors" *ngIf="match() && match().count > 1">
                <div>
                  <strong>{{ match().count }}</strong> other options from
                </div>
                <div class="other-vendors" *ngIf="match() && match().vendors.length > 0" [limUiTooltip]="vendorTooltip()">
                  <img
                    *ngFor="let vendorKey of vendorLogoKeys()"
                    [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                    class="vendor-icon"
                  />
                </div>
              </div>
              <div class="see-all" *ngIf="match() && match().count > groupedResultLimit" (click)="showDetails(heroItem())">
                See All
              </div>
            </div>
          </div>
        </ng-template>
      </custom-lim-ui-panel>
    </ng-container>
    <ng-template #dontShowBestMatch>
      <custom-lim-ui-panel
        [useCustomTitle]="true"
        [collapsable]="false"
        [collapsed]="true"
        [noPointerEvents]="true"
        [hidden]="removeBestMatch()"
        [noBorder]="true"
      >
        <div panelCustomTitle class="badge-container">
          <search-ui-badge *ngIf="showGroupingBadge()" [icon]="faSparkles" [label]="badgeLabel()" />
          <search-ui-badge label="Best Match" color="#E9F5EC" />
        </div>
      </custom-lim-ui-panel>
    </ng-template>
  </ng-container>
  <ng-template #responsiveBestMatch>
    <ng-container *ngIf="showBestMatchContainer()" else dontShowResponsiveBestMatch>
      <custom-lim-ui-panel [useCustomTitle]="true" [collapsable]="true" [collapsed]="false" [noBorder]="true">
        <div panelCustomTitle class="badge-container">
          <search-ui-badge *ngIf="showGroupingBadge()" [icon]="faSparkles" [label]="badgeLabel()" />
          <search-ui-badge label="Best Match" color="#E9F5EC" />
        </div>
        <div class="hero-section" (click)="showDetails(heroItem())" *ngIf="heroItem()">
          <div class="logo-container" *ngIf="!heroItem().isLocked">
            <div class="vendor-logo">
              <img [src]="heroItem().vendor.logoUrl" alt="Vendor logo" />
            </div>
            <div
              class="other-vendors"
              *ngIf="!heroItem().isLocked && match() && match().vendors.length > 0 && match().vendors.length <= 3"
              [limUiTooltip]="vendorTooltip()"
            >
              <img
                *ngFor="let vendorKey of vendorLogoKeys()"
                [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                class="vendor-icon"
              />
            </div>
            <div
              class="other-vendors"
              *ngIf="!heroItem().isLocked && match() && match().vendors.length > 3"
              [limUiTooltip]="vendorTooltip()"
            >
              <img
                *ngFor="let vendorKey of vendorLogoKeys().slice(0, 2)"
                [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                class="vendor-icon"
              />
              <div class="more-vendors">{{ match().vendors.length - 2 }}+</div>
            </div>
          </div>
          <div class="responsive-details">
            <div class="item-details">
              <div class="item-image-container">
                <img
                  [src]="
                    heroItem().mainImageUrl && heroItem().mainImageUrl.length === 0
                      ? '/assets/images/placeholder-img.png'
                      : heroItem().mainImageUrl
                  "
                  class="item-image"
                  alt="Item image"
                  onerror="this.onerror=null; this.src='/assets/images/placeholder-img.png'"
                />
                <div class="image-lock" *ngIf="heroItem().isLocked">
                  <fa-icon [icon]="lockKeyhole"></fa-icon>
                </div>
              </div>
              <div class="item-attributes">
                <div class="title" *ngIf="!heroItem().isLocked">{{ heroItem().title }}</div>
                <div class="title" *ngIf="heroItem().isLocked">
                  <div class="title-locked">&nbsp;</div>
                </div>
                <div>
                  <div class="price-container">
                    <div class="item-price">
                      {{ heroItem().price | currency }}
                    </div>
                  </div>
                  <shipping-details [item]="heroItem()"></shipping-details>
                </div>
              </div>
            </div>
            <div class="visit-site">
              <button class="btn-locked" *ngIf="heroItem().isLocked">
                View Result
                <fa-icon [icon]="lockKeyhole" size="lg"></fa-icon>
              </button>
              <button
                *ngIf="!heroItem().isLocked"
                (click)="openItemSite(heroItem().productUrl, heroItem().vendor, heroItem().id); $event.stopPropagation()"
                class="main-page-btn"
              >
                <span class="visit-site-badge">Visit Site</span>
              </button>
              <div class="comparison" *ngIf="!bestMatch().isLocked && match() && match().count > 0">
                <span *ngIf="match().vendors.length == 0">
                  Compare {{ match().count }} option{{ match().count > 1 ? 's' : '' }}
                </span>
                <span *ngIf="match().vendors.length > 0"> Compare {{ match().vendors.length }} vendors </span>
              </div>
            </div>
          </div>
        </div>
        <div class="buying-options" *ngIf="groupedItems().length > 1">
          <ul class="items">
            <li
              *ngFor="let item of groupedItems(); index as i"
              class="item-container"
              [class.selected]="item().id == heroItem().id"
              (click)="selectItem(item)"
            >
              <div class="responsive-item" [attr.data-item-id]="item().id">
                <div class="title" *ngIf="!item.isLocked">
                  {{ item.title }}
                </div>
                <div class="item-attributes">
                  <div class="vendor-logo">
                    <div class="vendor-locked" *ngIf="item.isLocked" [class]="{ full: i == 0 }">
                      <fa-icon [icon]="lockKeyhole" size="lg"></fa-icon>
                      <span *ngIf="i == 0">Lowest Price</span>
                    </div>
                    <img [src]="item?.vendor?.logoUrl" alt="Vendor Logo" *ngIf="!item.isLocked" />
                  </div>

                  <div class="detail-right">
                    <div class="price">
                      <div class="total">
                        {{ item?.price | currency }}
                      </div>
                      <div class="per" *ngIf="item?.pricePer !== item?.price">({{ item.pricePer | currency }}/count)</div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="more-options">
          <div class="all-vendors" *ngIf="match() && match().count > 1">
            <div>
              <strong>{{ match().count }}</strong> other options from
            </div>
            <div class="other-vendors" *ngIf="match() && match().vendors.length > 0" [limUiTooltip]="vendorTooltip()">
              <img
                *ngFor="let vendorKey of vendorLogoKeys()"
                [src]="'assets/images/vendor-icons/icon-' + vendorKey + '.png'"
                class="vendor-icon"
              />
            </div>
          </div>
          <div class="see-all" *ngIf="items.length > groupedResultLimit" (click)="showDetails(heroItem())">See All</div>
        </div>
      </custom-lim-ui-panel>
    </ng-container>
    <ng-template #dontShowResponsiveBestMatch>
      <custom-lim-ui-panel
        [useCustomTitle]="true"
        [collapsable]="false"
        [collapsed]="true"
        [noPointerEvents]="true"
        [hidden]="removeBestMatch()"
        [noBorder]="true"
      >
        <div panelCustomTitle class="badge-container">
          <search-ui-badge *ngIf="showGroupingBadge()" [icon]="faSparkles" [label]="badgeLabel()" />
          <search-ui-badge label="Best Match" color="#E9F5EC" />
        </div>
      </custom-lim-ui-panel>
    </ng-template>
  </ng-template>

  <div class="more-results-container" *ngIf="showRelevantResults">
    <div class="more-results">
      <div class="main-heading" data-cy="results-you-may-like" [ngClass]="{ 'responsive-results-heading': isMobile }">
        Results You May Like
      </div>
      <div class="setting" *ngIf="!isMobile">
        <lim-ui-dropdown-button [label]="getSortByLabel()" [widthFitContent]="true" [hidden]="!searchService.isPreviewing()">
          <div menu>
            <lim-ui-dropdown-text-item
              displayString="Relevance"
              outputString=""
              (itemSelectedEvent)="sortResults('relevance')"
            ></lim-ui-dropdown-text-item>
            <lim-ui-dropdown-text-item
              displayString="Price: Low to High"
              outputString=""
              (itemSelectedEvent)="sortResults('price_per:asc')"
            ></lim-ui-dropdown-text-item>
            <lim-ui-dropdown-text-item
              displayString="Price: High to Low"
              outputString=""
              (itemSelectedEvent)="sortResults('price_per:desc')"
            ></lim-ui-dropdown-text-item>
          </div>
        </lim-ui-dropdown-button>
        <skeleton-sort-animation [hidden]="searchService.isPreviewing()"></skeleton-sort-animation>
      </div>
    </div>
    <div>
      <!-- If search is still loading, only show top 5 results + loading placeholders -->
      <div class="more-search-results" *ngIf="!searchService.isPreviewing()">
        <part-search-item-card
          *ngFor="let item of searchService.top5GroupedSearchResults()"
          [item]="item"
          id="{{ 'item' + item.id }}"
        ></part-search-item-card>
        <item-loading-animation></item-loading-animation>
        <item-loading-animation></item-loading-animation>
        <item-loading-animation></item-loading-animation>
        <item-loading-animation></item-loading-animation>
      </div>

      <!-- If search is complete, show all results -->
      <div class="more-search-results" *ngIf="searchService.isPreviewing()">
        <part-search-item-card
          *ngFor="let item of relevantResults()"
          [item]="item"
          id="{{ 'item' + item.id }}"
        ></part-search-item-card>
      </div>
    </div>
  </div>
</section>
