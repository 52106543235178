<modal (close)="handleCloseModal()">
  <div class="searches-exceeded-content">
    <div class="title">You've Reached Your Search Limit!</div>
    <div class="body">
      <div class="count-container">
        <div class="text">You've used</div>
        <div class="count">{{ searchesUsed() }}</div>
        <div class="text">searches</div>
      </div>
      <div class="upgrade-msg">Upgrade now to unlock more searches and increase your savings on future purchases.</div>
    </div>
    <div class="actions">
      <button class="upgrade-btn" (click)="handleClickUpgrade()">
        {{ superUser() ? 'Upgrade Now' : 'Request to Upgrade' }}
      </button>
    </div>
  </div>
</modal>
