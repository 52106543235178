import { Component, signal, input, computed, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { faPuzzlePiece, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { AuthService, UserAccessStatus } from '../_services/auth.service';
import { MobileNavComponent } from '../mobile-nav/mobile-nav.component';
import { NgClass } from '@angular/common';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { DropdownComponent, DropdownTextItemComponent, IconComponent } from '@limblecmms/lim-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { toSignal } from '@angular/core/rxjs-interop';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { TrialService } from '../_services/trial.service';
import { EventBusService } from '../_shared/event-bus.service';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';
import { SubscriptionService } from '../_services/subscription.service';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [
    MobileNavComponent,
    NgClass,
    PartSearchFieldComponent,
    DropdownComponent,
    IconComponent,
    DropdownTextItemComponent,
    FontAwesomeModule,
    RouterLink,
    AppSwitcherComponent
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
  public faPuzzlePiece: IconDefinition = faPuzzlePiece;
  public faClock: IconDefinition = faClock;
  isMobile = input.required<boolean>();
  isLoggedIn = input.required<boolean>();
  responsivePositioning = input.required<boolean>();

  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly trialService = inject(TrialService);
  private readonly eventBusService = inject(EventBusService);
  private readonly currentRoute = signal('');
  private readonly subscriptionService = inject(SubscriptionService);
  public readonly isCmmsUser = this.authService.isCmmsUser;
  showMobileMenu = signal(false);
  public daysInTrialRemaining = toSignal(this.authService.daysInTrialRemaining$);
  private readonly userAccessStatus = toSignal(this.authService.userAccessStatus$);
  public showCMMSCustomerTrialEligible = computed(() => this.userAccessStatus() === UserAccessStatus.NoAccess);
  // placeholder for when we can check super user status
  public isCmmsSuperUser = this.authService.superUser;

  constructor() {
    // because the navigation component is a parent node of the <router-outlet> the activated route has no context of the current route so we need to manually look up the router events to find the current route path.
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.url);
      }
    });
  }

  showPartSearchField = computed(() => {
    if (this.isLoggedIn()) {
      if (this.routesMatch(['/results', '/details'])) {
        if (!this.isMobile()) {
          return true;
        }
      }
    }

    return false;
  });

  showNav = computed(() => {
    if (!this.routesMatch(['/cmms-authentication'])) {
      return true;
    }

    return false;
  });

  showHamburger = computed(() => {
    if (!this.routesMatch(['/login', '/register'])) {
      return true;
    }

    return false;
  });

  showLinks = computed(() => {
    if (!this.isLoggedIn() && this.routesMatch(['/'])) {
      return true;
    }

    if (this.routesMatch(['/explore'])) {
      return true;
    }

    return false;
  });

  registerButtonCopy = computed(() => {
    return this.routesMatch(['explored']) ? 'Start Free Trial' : 'Sign Up';
  });

  private routesMatch(routes: string[]): boolean {
    return routes.filter((route) => this.currentRoute().includes(route)).length > 0;
  }

  toggleMobileMenu() {
    this.showMobileMenu.update((value) => !value);

    if (this.showMobileMenu()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  goToAccount() {
    this.router.navigate(['/account/profile']);
  }

  goToPlanAndUsage() {
    this.router.navigate(['/account/subscriptions']);
  }

  goToVendorPrefs() {
    this.router.navigate(['/'], { queryParams: { vendorPrefs: 1 } });
  }

  logout() {
    localStorage.removeItem('extensionBannerHidden');
    this.authService.logout();
  }

  startTrialSubscription() {
    this.subscriptionService.createTrialSubscription().subscribe({
      next: () => {
        this.eventBusService.emit('StartTrialSubscription.Show', 20);
      },
      error: () => {
        console.error('Error starting trial subscription');
      }
    });
  }

  requestTrialFromCmmsSuperUser() {
    // const user = this.authService.user();
    // if (!user) {
    //   this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
    //   return;
    // }

    // const { superUserEmail } = user;
    // if (!superUserEmail) {
    //   this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
    //   return;
    // }

    this.subscriptionService.sendTrialRequestEmail().subscribe({
      next: () => {
        this.eventBusService.emit('StartTrialSubscription.Show', 20);
      },
      error: () => {
        this.eventBusService.emit('ErrorPageBanner.Show', 'Something went wrong, please try again');
      }
    });
  }
}
