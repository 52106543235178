import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'cmms-redirect',
  standalone: true,
  imports: [],
  templateUrl: './cmms-redirect.component.html',
  styleUrl: './cmms-redirect.component.scss'
})
export class CmmsRedirectComponent {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private authService = inject(AuthService);
  private notificationService = inject(NotificationService);
  public allowCmmsLogin = this.authService.allowCmmsLogin;

  constructor() {
    //TODO controls cmmslogin - remove this after the launch.
    if (!this.allowCmmsLogin()) {
      this.router.navigate(['/']);
      return;
    }
    const cmmsLogin = this.route.snapshot.queryParamMap.get('cmms-login');
    const authorizationCode = this.route.snapshot.queryParamMap.get('code');
    if (cmmsLogin) {
      this.authService.authorizeCMMSEndpoint().subscribe((url) => {
        window.location.replace(url.authorizeEndpoint);
      });
    } else if (authorizationCode) {
      this.authService.loginCMMSUser(authorizationCode).subscribe({
        next: () => {
          this.router.navigate(['/']);
        },
        error: () => {
          this.notificationService.error('Login Failed');
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/']);
    }
  }
}
