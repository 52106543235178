<div class="main-container">
  <div
    class="cover-image"
    [ngStyle]="{ minHeight: isMobile ? '512px' : showCMMSCustomerTrialEligible() ? '879px' : 'unset'  }"
  >
    <div
      class="overlay-content"
      [ngStyle]="{
        position: showQueryQuality || showVendorPrefs ? 'relative' : 'unset',
        marginTop: isMobile ? '40px' : showCMMSCustomerTrialEligible() ? '80px' : '160px',
        marginBottom: isMobile ? '40px' : showCMMSCustomerTrialEligible() ? '80px' : '48px'
      }"
    >
      <img src="/assets/images/AIPowered.svg" alt="AI Powered" />
      <h1 class="search-banner">High-Quality Parts from Trusted Vendors—All in One Place</h1>

      @if (showCMMSCustomerTrialEligible()) {
      <div class="cmms-trial-container">
        <p class="cmms-trial-description">
          Find the best prices on parts from multiple vendors. <br> Enjoy 20 free searches per year—upgrade anytime.
        </p>
        @if (superUser()) {
        <button class="get-started" (click)="startTrialSubscription()">Try for Free</button>
        } @else {
        <button class="get-started" (click)="requestTrialFromCmmsSuperUser()">Request to Try</button>
        }
      </div>
      <div class="full-results-container" (click)="showTakeTourModal()">
        @if (isMobile) {
        <img src="/assets/images/FullResultsWithButtonMobile.png" alt="Full Results" />
        } @else {
        <img src="/assets/images/FullResultsWithButton.png" alt="Full Results" />
        }
      </div>

      } @else {
      <div class="landing-part-search-container">
        <div class="landing-part-search-bar-container" [ngClass]="{ 'has-modal': showQueryQuality || showVendorPrefs }">
          <input
            name="part_search"
            id="part-search-field"
            [(ngModel)]="query"
            class="part-search"
            (keydown.enter)="search(query)"
            (focus)="showQueryQualityDropdown()"
            (blur)="showQueryQuality = false"
            placeholder="Search by Part Name, Part Detail, etc."
            autocomplete="off"
          />
          <button class="search-button" (click)="search(query)">
            <lim-ui-icon icon="magnifyingGlass" iconSize="small" />
          </button>
        </div>
        <query-quality-dropdown
          [query]="query"
          [show]="showQueryQuality"
          style="position: absolute"
          [ngClass]="{ 'query-quality-dropdown': showQueryQuality }"
        ></query-quality-dropdown>
      </div>
      } @if (confirmTrialModal()) {
      <modal (close)="hideConfirmTrialModal()">
        <div class="confirm-trial-modal-container">
          <div class="icon-container">
            @if (superUser()) {
            <img src="/assets/images/confirm-trial-icon.svg" alt="Confirm Trial Icon" />
            } @else {
            <img src="/assets/images/trial-request-icon.svg" alt="Trial Request Icon" />
            }
          </div>
          <div class="title">{{ superUser() ? 'You’re All Set!' : "We've sent your request!" }}</div>
          @if (superUser()) {
          <div class="description">
            Your team now has <span>{{ selectedPlanCeiling() | number }}</span> {{ selectedPlanCeiling() === 20 ? 'free': '' }} searches per year! Start searching today and upgrade anytime.
          </div>
          } @else {
          <div class="description">
            Stay tuned! We've emailed your administrator (<span>{{ superUserEmail() }}</span
            >) about your interest in trying Limble Search.
          </div>
          }
          <button class="get-started-button" (click)="hideConfirmTrialModal()">
            {{ superUser() ? 'Get Started' : 'Done' }}
          </button>
        </div>
      </modal>
      } @if (takeTourModal()) {
      <modal (close)="hideTakeTourModal()">
        <div class="tour-modal-container">
          <div class="tour-modal-title-container">
            <div class="tour-modal-title">Right Part. Right Price. Right Now.</div>
            <div class="tour-modal-description">
              Easily compare parts across vendors with AI-powered technology—seamlessly integrated with your Limble CMMS to
              simplify parts searching and purchasing.
            </div>
          </div>
          <wistia-player media-id="xdvjl36ooo"></wistia-player>
          <div class="tour-modal-footer-container">
            @if (superUser()) {
            <div class="tour-modal-footer-description">Enjoy 20 free searches per year—upgrade anytime.</div>
            <button (click)="startTrialSubscription()" class="free-trial-button">Try for Free</button>
            <a class="tour-modal-footer-link" routerLink="/account/subscriptions">Explore Pricing Plans</a>
            } @else {
            <button
              class="request-trial-button"
              (click)="!showCMMSCustomerTrialEligible() ? hideTakeTourModal() : requestTrialFromCmmsSuperUser()"
            >
              {{ !this.showCMMSCustomerTrialEligible() ? 'Get Started' : 'Request to Try' }}
            </button>
            }
          </div>
        </div>
      </modal>
      } @if(isLoggedIn() && !showCMMSCustomerTrialEligible()) {
      <div style="position: relative; width: 100%">
        <a (click)="showVendorPrefs = !showVendorPrefs" class="vendor-prefs-link">
          <fa-icon [icon]="slider" />
          Vendor Preferences
        </a>
        <vendor-prefs
          [show]="showVendorPrefs"
          (onClose)="showVendorPrefs = false"
        ></vendor-prefs>
      </div>
      }
    </div>
  </div>
  @if (isLegacyUser() || showCMMSCustomerTrialEligible() || !isLoggedIn()) {
  <div
    class="most-searched-items"
    [ngStyle]="{ marginTop: isMobile ? '131px' : showCMMSCustomerTrialEligible() ? '236px' : '80px' }"
  >
    <div class="most-searched-items-title">
      <h2>Most Searched Items</h2>
    </div>
    <div class="popular-products">
      @for (item of popularItems; track item.partNumber) {
      <div class="popular-card">
        <div class="card-image">
          <img src="/assets/images/product-images/{{ item.partImage }}" alt="Product Image" />
        </div>
        <div class="card-details">
          <div class="details">Up to</div>
          <div class="savings">{{ item.priceVariance }}% Savings</div>
          <div class="details">across {{ item.noOfVendors }} vendors</div>
          <div class="part-info">{{ item.itemTitle }} {{ item.partNumber }}</div>
        </div>
      </div>
      }
    </div>
  </div>
  <div class="vendor-section">
    <h2>Shop 150M+ Parts from Top Vendors</h2>
    <div class="vendor-container">
      @if (isMobile) {
      <div class="row">
        <img src="/assets/images/vendor-logos/grainger-logo.svg" alt="Grainger" />
        <img src="/assets/images/vendor-logos/mcmaster-logo.svg" alt="McMaster-Carr" />
        <img src="/assets/images/vendor-logos/zoro-logo.svg" alt="Zoro" />
      </div>
      <div class="row">
        <img src="/assets/images/vendor-logos/fastenal-logo.svg" alt="Fastenal" />
        <img src="/assets/images/vendor-logos/amazon-logo.svg" alt="Amazon" />
        <img src="/assets/images/vendor-logos/ebay-logo.svg" alt="Ebay" />
      </div>
      <div class="row">
        <img src="/assets/images/vendor-logos/advanced-auto-logo.svg" alt="Advanced Auto" />
        <img src="/assets/images/vendor-logos/oreilly-logo.svg" alt="Oreilly" />
      </div>
      } @else {
      <div class="row">
        <img src="/assets/images/vendor-logos/grainger-logo.svg" alt="Grainger" />
        <img src="/assets/images/vendor-logos/amazon-logo.svg" alt="Amazon" />
        <img src="/assets/images/vendor-logos/fastenal-logo.svg" alt="Fastenal" />
        <img src="/assets/images/vendor-logos/mcmaster-logo.svg" alt="McMaster-Carr" />
        <img src="/assets/images/vendor-logos/motion-logo.svg" alt="Motion" />
        <img src="/assets/images/vendor-logos/msc-logo.svg" alt="MSC" />
        <img src="/assets/images/vendor-logos/ebay-logo.svg" alt="Ebay" />
      </div>
      <div class="row">
        <img src="/assets/images/vendor-logos/walmart-logo.svg" alt="Walmart" />
        <img src="/assets/images/vendor-logos/new-home-depot-logo.svg" alt="Home Depot" />
        <img src="/assets/images/vendor-logos/zoro-logo.svg" alt="Zoro" />
        <img src="/assets/images/vendor-logos/bing-logo.svg" alt="Bing" />
        <img src="/assets/images/vendor-logos/google-shopping.png" alt="Google Shopping" />
        <img src="/assets/images/vendor-logos/uline-logo.svg" alt="Uline" />
        <img src="/assets/images/vendor-logos/ferguson-logo.svg" alt="Ferguson" />
      </div>
      <div class="row">
        <img src="/assets/images/vendor-logos/global-industrial-logo.png" alt="Global Industrial" />
        <img src="/assets/images/vendor-logos/applied-industrial-logo.png" alt="Applied Industrial" />
        <img src="/assets/images/vendor-logos/rs-logo.jpg" alt="RS Online" />
        <img src="/assets/images/vendor-logos/radwell-logo.png" alt="Radwell" />
        <img src="/assets/images/vendor-logos/advanced-auto-logo.svg" alt="Advanced Auto" />
        <img src="/assets/images/vendor-logos/autozone-logo.png" alt="AutoZone" />
        <img src="/assets/images/vendor-logos/oreilly-logo.svg" alt="Oreilly" />
        <img src="/assets/images/vendor-logos/napa-logo.svg" alt="Napa" />
      </div>
      }
    </div>
  </div>

  <div class="part-search-section">
    <div class="part-search-section-title">
      <h2>Revolutionize Your Part Search</h2>
    </div>
    <div class="part-search-attribute-container">
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="sparkles" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">AI-Powered Part Search</div>
        <div class="details">Leverages advanced AI-powered technology to make comparing prices for parts effortless.</div>
      </div>
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="circleDollar" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">Save on Every Purchase</div>
        <div class="details">
          Limble Search ensures you save money on every purchase with live results and the latest pricing.
        </div>
      </div>
      <div class="part-search-attribute">
        <div class="icon-container">
          <fa-icon [icon]="clock" [style.color]="'#289E49'" size="lg" transform="grow-4" />
        </div>
        <div class="header">Save Time Shopping</div>
        <div class="details">
          Access Grainger, McMaster-Carr, Zoro, Uline, MSC, Fastenal, Amazon, and more - all in one place.
        </div>
      </div>
    </div>
  </div>

  <div class="customer-reviews-section">
    <div class="customers-title">
      <h2>What Our Customers Say</h2>
    </div>
    <div class="customer-reviews">
      @for (review of customerReviews; track review.name) {
      <div class="review-card">
        <div class="review">
          <img src="/assets/images/quote-icon.svg" alt="Quote Icon" />
          <div class="review-content">{{ review.reviewContent }}</div>
          <div class="reviewer-details">
            <div class="reviewer-name">{{ review.name }}</div>
            <div class="reviewer-title">{{ review.jobTitle }}</div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
  } @else {
  <div class="legacy-user-container"></div>
  }
</div>
