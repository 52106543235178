import { computed, Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, interval, Subscription, forkJoin } from 'rxjs';
import { ItemsService } from './items.service';
import { FeatureService } from './feature.service';
import { CONFIG } from '../../environments/environment';
import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';
import { affliateLinks } from '../_util/constants';
import { AuthService } from './auth.service';
import { User } from '../_types/user';
import { Location } from '@angular/common';
import { SearchQueryParams } from '../_types/search';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

type ErrorMessage = {
  status: string;
  message: string;
};

const searchUrl = CONFIG.API_URL + 'search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public readonly isSearching = signal(false);
  public readonly totalUnseenRelevantItems = signal(0);
  public readonly query = signal('');
  public readonly sortBy = signal('relevance');
  public readonly selectedItem = signal<any>(null);
  public readonly selectedDetailsPageItem = signal<any>(null);
  public readonly filteredSearchResults = signal<any>([]);
  public readonly hiddenItems = signal<any>([]);
  public readonly error = signal<ErrorMessage | null>(null);
  public readonly searchModalState = signal<boolean>(false);
  public readonly searchComplete = signal<boolean>(false);
  public readonly isPreviewing = signal<boolean>(false);
  public readonly percentComplete = signal<number>(0);
  public readonly searchResults = signal<any[]>([]);
  public readonly queryQuality = signal<any>({});
  public readonly isCachedQuery = signal<boolean>(false);
  public readonly isPastResult = signal<boolean>(false);
  public readonly searchResultsMeta = signal<any>(null);
  public readonly partNumberFound = signal<boolean>(false);
  public readonly isQueryExpanded = signal<boolean>(false);
  public readonly totalItems = signal<number>(0);
  public readonly bestMatch = signal<any>(null);
  public readonly filters = signal<any>({});
  public readonly isMarketingMode = signal<boolean>(false);
  public readonly isItemsRestricted = computed(() => this.isMarketingMode() && !this.authService.isLoggedIn());
  public readonly filteredSearchResultsMeta = signal<any>([]);
  public readonly selectedAttributeGroups = signal<SelectedAttributeGroups | null>(null);

  public readonly irrelevantResults = computed(() => {
    const results = this.searchResults();
    const irrelevantSortVendors = ['grainger', 'motion industries', 'fastenal', 'zoro', 'mcmaster-carr'];
    const irrelevantResults = results.filter((item: any) => {
      if (item.score <= 0) {
        return item;
      }
    });

    //sort the irrelevant results by the irrelevantSortVendors
    irrelevantResults.sort((a: any, b: any) =>
      irrelevantSortVendors.includes(a.vendor.name.toLowerCase()) &&
      !irrelevantSortVendors.includes(b.vendor.name.toLowerCase())
        ? -1
        : 1
    );
    return irrelevantResults;
  });

  public readonly relevantResults = computed(() => {
    const results = this.searchResults();
    return results.filter((item: any) => item.score > 0);
  });

  public readonly top5GroupedSearchResults = computed(() => this.searchResults().slice(0, 5));

  private isStartingSearch: boolean = false;
  private isWaitingForResults: boolean = false;
  private pollingMsFrequency: number = 2500;
  private pollingtMsTimeout: number = 90000; // 1.5 minutes, max time we will wait for a search to finish
  private previewWaitMs: number = 15000;
  private pollingStartTime: any;
  private previewVendors: Set<number> = new Set<number>();
  private vendorsSeen: any = [];
  private previewStarted: boolean = false;

  private rawResultsCached: any = [];
  private rawResults: any = [];

  private pollingSub$: Subscription | undefined;
  private progressSub$: Subscription | undefined;
  private currentUser = this.authService.user;

  constructor(
    private itemsService: ItemsService,
    private featureService: FeatureService,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public processResults() {
    if (this.rawResultsCached.length > 0) {
      this.rawResults = this.rawResultsCached;
      this.rawResultsCached = [];
    }

    let finalResults = this.rawResults;
    finalResults = this.filterResults(finalResults);
    this.totalItems.set(finalResults.length);

    const deDuplicatedResults = this.deDuplicateGroups(finalResults);

    // Sort results
    const sortBy = this.sortBy();

    if (sortBy === 'price_per:asc') {
      deDuplicatedResults.sort((a: any, b: any) => a.price - b.price);
    } else if (sortBy === 'price_per:desc') {
      deDuplicatedResults.sort((a: any, b: any) => b.price - a.price);
    } else {
      deDuplicatedResults.sort((a, b) => a.rank - b.rank);
    }

    this.searchResults.set(deDuplicatedResults);
    this.updateMeta();
    if (this.sortBy() == 'relevance') {
      const bestMatch = this.bestMatch();
      if (
        !bestMatch ||
        (bestMatch && !bestMatch.id) ||
        (bestMatch && bestMatch.id && deDuplicatedResults.length && bestMatch.id !== deDuplicatedResults[0].id)
      ) {
        const topRankedResult = deDuplicatedResults.find((item: any) => item.rank !== null && item.productUrl);
        if (topRankedResult) {
          this.bestMatch.set(topRankedResult);
        }
      }

      if (bestMatch && bestMatch.id && deDuplicatedResults.length && bestMatch.id === deDuplicatedResults[0].id) {
        const selectedAttributeGroup = this.selectedAttributeGroups();

        if (selectedAttributeGroup) {
          const groupId = Object.values(selectedAttributeGroup)[0]?.id;
          const currentMatch = bestMatch.matches.find(({ itemGroupId }) => itemGroupId === groupId) as
            | ItemGroupMatch
            | undefined;
          const newMatch = deDuplicatedResults[0].matches.find(({ itemGroupId }) => itemGroupId === groupId) as
            | ItemGroupMatch
            | undefined;

          if (currentMatch && newMatch && currentMatch.items.length !== newMatch.items.length) {
            const topRankedResult = deDuplicatedResults.find((item: any) => item.rank !== null && item.productUrl);
            if (topRankedResult) {
              this.bestMatch.set(topRankedResult);
            }
          }
        }
      }
    }
  }

  filterResults(results: any) {
    let filteredResults = results;
    let filters = this.filters();
    for (let field in filters) {
      filteredResults = filteredResults.filter((item: any) => {
        if (filters[field].operator === 'range') {
          if (item[field] >= filters[field].values[0] && item[field] <= filters[field].values[1]) {
            return true;
          }
          return false;
        } else if (filters[field].operator === 'includes') {
          if (field === 'vendorId') {
            // Loop through values and add to vendorsSeen
            filters[field].values.forEach((vendorId: any) => {
              if (!this.vendorsSeen.includes(vendorId)) {
                this.vendorsSeen.push(vendorId);
              }
            });
          }

          if (filters[field].values.includes(item[field])) {
            return true;
          }
          return false;
        }

        return true;
      });
    }

    return filteredResults;
  }

  deDuplicateGroups(results: any[]) {
    const itemGroupValueIds = new Set<number>();
    const resultSet: any[] = [];
    const selectedAttributeGroup = this.selectedAttributeGroups();

    if (selectedAttributeGroup) {
      const groupId = Object.values(selectedAttributeGroup)[0]?.id;

      results.forEach((item) => {
        if (!item.matches) {
          resultSet.push(item);
          return;
        }

        const matches = item.matches
          .filter(({ itemGroupId }) => itemGroupId === groupId)
          .sort((a, b) => b.items.length - a.items.length) as ItemGroupMatch[];
        const match = matches[0];
        if (!match) {
          resultSet.push(item);
          return;
        }

        if (itemGroupValueIds.has(match.itemGroupValueId)) {
          return;
        }

        itemGroupValueIds.add(match.itemGroupValueId);
        resultSet.push(item);
      });

      return resultSet;
    }

    return results;
  }

  getQueryQuality(query: string): Observable<any> {
    return this.http.post<any>(`${searchUrl}/query-quality`, { query }, httpOptions);
  }

  setItemLabel(itemId: any, key: string, value: any) {
    let items = this.rawResults;
    let item = items.find((item: any) => item.id == itemId);

    if (item) {
      if (typeof item.labels === 'undefined') {
        item.labels = {};
      }
      item.labels[key] = value;
    }
  }

  getItemLabel(itemId: any, key: string) {
    let items = this.rawResults;
    let item = items.find((item: any) => item.id == itemId);

    if (item && item.labels && item.labels[key]) {
      return item.labels[key];
    }
    return null;
  }

  feedbackDislike(): void {
    this.http.post<any>(`${searchUrl}/feedback/dislike`, null).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  feedbackLike(): void {
    this.http.post<any>(`${searchUrl}/feedback/like`, null).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  feedbackComment(comments: string): void {
    this.http.post<any>(`${searchUrl}/feedback`, { comments }).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  getSimilarItems(id: number, without?: any): Observable<any> {
    let commaSeperatedWithout = '';
    if (without) {
      commaSeperatedWithout = without.join(',');
    }

    return this.http.get<any>(`${searchUrl}/similar/${id}/${commaSeperatedWithout}`, httpOptions);
  }

  getLatestResults(searcHistoryId: any): void {
    // If we are still waiting for a response, don't make another request
    if (this.isWaitingForResults) {
      return;
    }
    this.isWaitingForResults = true;

    const now = Date.now();
    let timeoutExceeded = false;
    let isPreviewing = false;

    let url = `${searchUrl}/results/${searcHistoryId}`;

    if (this.isMarketingMode()) {
      url = `${searchUrl}/explore/${searcHistoryId}`;
    }

    // Safety measure. If we are still not done after X seconds, stop polling.
    // Its possible there was a problem on the backend
    if (now > this.pollingStartTime + this.pollingtMsTimeout) {
      timeoutExceeded = true;
    }

    // If we surpassed the preview wait time, we will start showing full search results while we wait
    // for the search to fully complete
    if (now > this.pollingStartTime + this.previewWaitMs) {
      isPreviewing = true;
    }

    if (isPreviewing || timeoutExceeded) {
      url = `${url}/full`;
    }

    const itemRequest = this.http.get<any>(url, httpOptions);
    const groupRequest = this.http.get<any>(`${searchUrl}/groups/${searcHistoryId}`, httpOptions);

    forkJoin([itemRequest, groupRequest]).subscribe({
      next: ([data, groupResponse]) => {
        const items = this.populateMatchGroups(data.items, groupResponse);
        this.isWaitingForResults = false;

        if (data.meta?.percentComplete === 100) {
          // Don't set completed too early.
          // this._searchComplete.next(true);
          this.percentComplete.set(0);
        } else {
          // only set the percent complete if it is greater than the current percent complete to prevent progress bar from going backwards
          const currentPercentComplete = this.percentComplete();
          if (data.meta && data.meta.percentComplete > currentPercentComplete) {
            this.percentComplete.set(data.meta?.percentComplete);
          }
        }

        this.partNumberFound.set(data.meta?.partNumberFound);
        this.isQueryExpanded.set(data.meta?.isQueryExpanded);
        this.queryQuality.set(data.meta?.queryQuality);

        if (data.meta?.query) {
          this.query.set(data.meta.query);
        }

        if (data.meta?.features) {
          this.featureService.setFeatures(data.meta.features);
        }

        if (data.meta?.vendorCounts) {
          // Before we allow the user to preview the results, filter by every vendor that is completed
          data.meta.vendorCounts.forEach((vendor: any) => {
            if (
              vendor.status !== 'loading' &&
              vendor.itemCount > 0 &&
              vendor.userEnabled &&
              vendor.vendorId &&
              !this.previewVendors.has(vendor.vendorId)
            ) {
              this.previewVendors.add(vendor.vendorId);
              const currentFilters = this.filters();
              const vendorFilter = currentFilters['vendorId'];

              const updatedFilterVendorIds = vendorFilter
                ? [...vendorFilter.values, vendor.vendorId]
                : Array.from(this.previewVendors);
              this.addFilter({
                field: 'vendorId',
                values: updatedFilterVendorIds,
                operator: 'includes'
              });
              this.processResults();
            }
          });
        }

        // If the user is already previewing the results, cache the full results for later behind the scenes
        // so the item list doesn't jump around while we load things in the background
        if (this.previewStarted && data.meta?.isFullResponse) {
          this.rawResultsCached = items;

          let totalRelevantItems = 0;
          items.forEach((item) => {
            if (this.vendorsSeen.includes(item.vendorId)) {
              return;
            }
            if (item.score > 0) {
              totalRelevantItems++;
            }
          });

          this.totalUnseenRelevantItems.set(totalRelevantItems);
        }

        if (data.meta?.vendorCounts) {
          data.meta.vendorCounts.forEach((vendor: any) => {
            if (this.previewVendors.has(vendor.vendorId)) {
              vendor.isFast = true;
            } else {
              vendor.isFast = false;
            }
          });
        }

        if (data.meta) {
          this.searchResultsMeta.set(data.meta);
          this.filteredSearchResultsMeta.set(data.meta);

          // update cached user data with searches remaining
          const user = this.currentUser();
          if (data.meta.searchesRemaining !== null && user) {
            const updatedUser: User = { ...user, searchesRemaining: data.meta.searchesRemaining };
            this.authService.saveUser(updatedUser);
          }

          // Set the default attribute group. For now we will default to the Part Number group
          const currentSelectedAttributeGroups = this.selectedAttributeGroups();
          const defaultGroupSelection = data.meta.attributeGroups.find(({ name }) => name === 'Part Number');

          if (!currentSelectedAttributeGroups) {
            if (defaultGroupSelection) {
              this.selectedAttributeGroups.set({ [defaultGroupSelection.id]: defaultGroupSelection });
            }
          } else {
            const inTop5 = data.meta.attributeGroups
              .map((attributeGroup) => attributeGroup.id)
              .includes(parseInt(Object.keys(currentSelectedAttributeGroups)[0], 10));

            if (!inTop5) {
              this.selectedAttributeGroups.set({ [defaultGroupSelection.id]: defaultGroupSelection });
            }
          }
        }

        if (!this.previewStarted) {
          this.rawResults = items;
          this.processResults();
        }

        if (isPreviewing) {
          this.previewStarted = true;
          this.isPreviewing.set(true);
        }

        if (data.meta?.complete && data.meta?.totalTasks > 0) {
          this.processResults();
          this.pollingSub$?.unsubscribe();
          this.isSearching.set(false);
          this.percentComplete.set(0);
          this.searchComplete.set(true);
          this.isPreviewing.set(true);
        } else if (data.meta?.percentComplete === 100) {
          // Handle possible edge case.
          this.searchComplete.set(true);
        }

        // Stop polling if we exceeded the timeout threshold before results were finished
        if (timeoutExceeded) {
          this.processResults();
          this.pollingSub$?.unsubscribe();
          this.isSearching.set(false);
          this.percentComplete.set(0);
          this.searchComplete.set(true);
          this.isPreviewing.set(true);
          console.warn(`Search took longer than ${this.pollingtMsTimeout}. Stopping polling.`);

          //only error if we have no results after 60 seconds
          if (this.totalItems() == 0) {
            this.error.set({ status: 'timeout', message: 'Search took too long. Stopping polling.' });
          }
        }
      },
      error: (err) => {
        this.isWaitingForResults = false;
        this.isSearching.set(false);
        this.percentComplete.set(0);
        this.searchComplete.set(true);
        this.isPreviewing.set(true);

        this.pollingSub$?.unsubscribe();

        // TODO: Turn this into a global error handling function to parse errors
        if (err.error) {
          try {
            const res = JSON.parse(err.error);
            this.error.set({ status: 'system', message: 'Vendor Search Error' });
          } catch {
            this.error.set({ status: 'system', message: 'Service Error' });
          }
        } else {
          this.error.set({ status: 'system', message: 'Unknown Error' });
        }
      }
    });
  }

  reset(): void {
    // If a search is already in progress, kill it
    this.pollingSub$?.unsubscribe();

    // Reset the search results
    this.partNumberFound.set(false);
    this.isQueryExpanded.set(false);
    this.isPastResult.set(false);
    this.isCachedQuery.set(false);
    this.queryQuality.set(null);
    this.percentComplete.set(0);
    this.searchComplete.set(false);
    this.searchResults.set([]);
    this.filteredSearchResults.set([]);
    this.totalItems.set(0);
    this.searchResultsMeta.set([]);
    this.filteredSearchResultsMeta.set([]);
    this.isPreviewing.set(false);
    this.filters.set({});
    this.selectedAttributeGroups.set(null);
    this.vendorsSeen = [];
    this.rawResultsCached = [];
    this.rawResults = [];
    this.previewStarted = false;
    this.totalUnseenRelevantItems.set(0);
    this.previewVendors.clear();
    this.bestMatch.set(null);
  }

  search(query?: string | undefined): void {
    if (this.isStartingSearch) {
      return;
    }
    this.isStartingSearch = true;

    this.isSearching.set(true);
    this.reset();

    if (query !== undefined) {
      this.filters.set({});
      this.query.set(query);
    } else {
      query = this.query();
    }

    let params = {
      query: this.query()
    };

    if (localStorage.getItem('userLocation')) params['location'] = JSON.parse(localStorage.getItem('userLocation') || '');
    this.http.post<any>(searchUrl, params, httpOptions).subscribe({
      next: (data) => {
        this.pollingStartTime = Date.now();
        this.pollingSub$?.unsubscribe();

        if (typeof data.features !== 'undefined') {
          this.featureService.setFeatures(data.features);
        }

        if (data.id) {
          this.pollingSub$ = interval(this.pollingMsFrequency).subscribe(() => {
            this.getLatestResults(data.id);
          });
        }

        if (data?.cachedQuery) {
          this.isCachedQuery.set(true);
        }

        this.isStartingSearch = false;
      },

      error: (err) => {
        this.pollingSub$?.unsubscribe();
        this.isStartingSearch = false;

        // TODO: Turn this into a global error handling function to parse errors
        if (err.error) {
          try {
            const res = JSON.parse(err.error);
            this.error.set({ status: 'system', message: 'Vendor Search Error' });
          } catch {
            this.error.set({ status: 'system', message: 'Service Error' });
          }
        } else {
          this.error.set({ status: 'system', message: 'Unknown Error' });
        }
      }
    });
  }

  addFilter(filter: any) {
    /** 
    Example Filters:
    {
      field: "vendorId",
      values: "[1,2,3]",
      operator: "includes"
    } 

    {
      field: "pricePer",
      values: "[1,2]",
      operator: "range"
    }
    **/

    // Remove filter if it already exists
    this.removeFilter(filter.field);
    const currentFilters = this.filters();

    // Only add "includes" filter if at least one value is present
    if (filter.operator === 'includes' && filter.values.length === 0) {
      return;
    }

    // Only add "range" filter if at least one value is bounded
    if (filter.operator === 'range' && filter.values[0] === -Infinity && filter.values[1] === Infinity) {
      return;
    }

    // Add object with field, operator, and values to filters observable object, keyed by field
    this.filters.set({
      ...currentFilters,
      [filter.field]: filter
    });
  }

  removeFilter(field: string): void {
    let filters = this.filters();
    delete filters[field];
    this.filters.set(filters);
  }

  updateMeta(): void {
    try {
      const filteredMeta = {} as any;
      const vendorCounts = [] as any[];

      // add all vendors from initial search results to 'vendorCounts' array
      const searchResultsMeta = this.searchResultsMeta();
      if (searchResultsMeta && searchResultsMeta.vendorCounts)
        searchResultsMeta.vendorCounts.forEach((item: any) => {
          vendorCounts.push({
            vendorId: item.vendorId,
            vendorName: item.vendorName,
            itemCount: 0,
            priceRange: {
              highestPrice: 0,
              lowestPrice: 0
            }
          });
        });

      // To update the meta properties after filtering results,
      // we need to act separately for the price related values and the vendor related values
      // If a vendor filter is applied, we should update the price range meta but not the
      // vendor counts meta, for example. To do this, we will process each portion separately,
      // referencing the latest unfiltered results and meta values to provide
      // the updated filteredMeta values. Finally we will return both portions
      // together in the 'filteredMeta' object.

      //first, need to get the current filters so we can update meta properties correctly
      const currentFilters = this.filters();
      const unfilteredResults = this.rawResults;
      const unfilteredResultsMeta = this.searchResultsMeta();

      //PRICE RANGE META UPDATE. We will set 'globalHighestPrice' and 'globalLowestPrice' based on current vendor filter, or all vendors if no vendor filter is present
      let highestPrice = 0;
      let lowestPrice = 0;
      let selectedVendors = [] as any[];

      if (currentFilters['vendorId']) {
        selectedVendors = currentFilters['vendorId'].values;
      } else {
        //if no vendor filter is present, we will use all vendors
        selectedVendors = vendorCounts.map((item: any) => item.vendorId);
      }

      //VENDOR COUNTS META UPDATE. We will set vendorCoutns array based on current price filter, or from unfiltered meta if no price filter is present
      let selectedPriceRange = [] as any[];
      if (currentFilters['pricePer']) {
        selectedPriceRange = currentFilters['pricePer'].values;
      } else {
        //if no price filter is present, we will use all prices
        selectedPriceRange = [unfilteredResultsMeta.globalLowestPrice, unfilteredResultsMeta.globalHighestPrice];
      }

      //loop through unfiltered results and update vendorCounts array
      //loop through selectedVendors results in unfiltered results and update highestPrice and lowestPrice
      if (unfilteredResults) {
        unfilteredResults.forEach((item: any) => {
          if (selectedVendors.includes(item.vendorId)) {
            if (item.pricePer > highestPrice) {
              highestPrice = item.pricePer;
            }
            if (item.pricePer < lowestPrice || lowestPrice === 0) {
              lowestPrice = item.pricePer;
            }
          }

          if (item.pricePer >= selectedPriceRange[0] && item.pricePer <= selectedPriceRange[1]) {
            const vendorIndex = vendorCounts.findIndex((vendor: any) => vendor.vendorId === item.vendorId);
            if (vendorIndex === -1) {
              vendorCounts.push({
                vendorId: item.vendorId,
                vendorName: item.vendorName,
                itemCount: 1,
                priceRange: {
                  highestPrice: item.pricePer,
                  lowestPrice: item.pricePer
                }
              });
            } else {
              vendorCounts[vendorIndex].itemCount++;
              if (item.pricePer > vendorCounts[vendorIndex].priceRange.highestPrice) {
                vendorCounts[vendorIndex].priceRange.highestPrice = item.pricePer;
              }
              if (
                item.pricePer < vendorCounts[vendorIndex].priceRange.lowestPrice ||
                vendorCounts[vendorIndex].priceRange.lowestPrice === 0
              ) {
                vendorCounts[vendorIndex].priceRange.lowestPrice = item.pricePer;
              }
            }
          }
        });
      }

      filteredMeta['vendorCounts'] = vendorCounts;
      filteredMeta['globalHighestPrice'] = highestPrice;
      filteredMeta['globalLowestPrice'] = lowestPrice;

      this.filteredSearchResultsMeta.set(filteredMeta);
    } catch (err) {
      console.log(`ERROR WHEN UPDATING META WITH FILTERED RESULTS: ${err}`);
    }
  }

  setAffiliateLink(productUrl: any, vendorKey: any) {
    if (!this.featureService.enabled('affiliate-links')) {
      return productUrl;
    }
    const getAffiliate = affliateLinks.find((affiliate) => affiliate.vendorKey === vendorKey);
    if (!getAffiliate || !getAffiliate.useAffiliateLink) {
      return productUrl;
    }
    let affiliateProductUrl = productUrl;
    //extra check to make sure the url is valid
    try {
      affiliateProductUrl = new URL(productUrl);
    } catch (err) {
      console.log(err);
      return productUrl;
    }
    const { affiliateLinkType, affiliateData } = getAffiliate;
    if (affiliateLinkType === 'parameter') {
      const params = new URLSearchParams(affiliateProductUrl.searchParams);
      params.append(affiliateData.key, affiliateData.value);
      const newUrl = new URL(`${affiliateProductUrl.origin}${affiliateProductUrl.pathname}?${params.toString()}`).toString();
      return newUrl;
    }
  }

  startProgressBar() {
    this.percentComplete.set(99);
    this.searchComplete.set(false);
  }

  stopProgressBar() {
    this.searchComplete.set(true);
    this.percentComplete.set(0);
    this.progressSub$?.unsubscribe();
  }
  filterGroupItems(items: any) {
    const filters = this.filters();
    const filterKeys = Object.keys(filters);
    if (!filterKeys.length) {
      return items;
    }

    const filteredGroupItems: any = Object.keys(filters).reduce((acc, cv) => {
      const itemsToFilter = acc.length ? acc : items;
      if (cv === 'vendorId') {
        if (filters[cv].values.length === 0) {
          return itemsToFilter;
        }
        return itemsToFilter.filter((groupedItem: any) => {
          return filters[cv].values.includes(groupedItem.vendor.id);
        });
      }

      if (cv === 'pricePer') {
        return itemsToFilter.filter((groupedItem: any) => {
          return groupedItem[cv] >= filters[cv].values[0] && groupedItem[cv] <= filters[cv].values[1];
        });
      }
    }, []);

    return filteredGroupItems;
  }

  getVendorsFromFilteredGroupItems(items: any) {
    const uniqueVendorIds = new Set();
    const uniqueVendors: any = [];

    items
      .map((item) => item.vendor)
      .forEach((vendor) => {
        if (!uniqueVendorIds.has(vendor.id)) {
          uniqueVendors.push(vendor);
        }

        uniqueVendorIds.add(vendor.id);
      });

    return uniqueVendors;
  }

  populateMatchGroups(itemObj: any, groups: any) {
    if (!itemObj) {
      return [];
    }

    const rawItems = Object.values(itemObj);

    if (!groups) {
      return rawItems.map((item: any) => ({
        ...item,
        matches: []
      }));
    }

    const items = rawItems.map((item: any) => {
      if (!item.matches) {
        return {
          ...item,
          matches: []
        };
      }

      const matches: any[] = [];
      item.matches.forEach((groupId) => {
        const group = groups[groupId];

        if (group && group.items) {
          const groupItems = group.items
            .map((itemId) => itemObj[itemId])
            .filter((item) => !!item && item.pricePer > 0)
            .sort((a, b) => a.pricePer - b.pricePer);
          matches.push({
            ...group,
            items: groupItems
          });
        }
      });

      return {
        ...item,
        matches
      };
    });

    return items.sort((a, b) => a.rank - b.rank);
  }

  openItemSite(productUrl: string, vendor: any, itemId: number) {
    const openLink = this.setAffiliateLink(productUrl, vendor.key);
    this.itemsService.trackClick(itemId, 'productLink').subscribe();
    window.open(openLink, '_blank');
  }

  getUpdatedUrl(minPrice?: number | null, maxPrice?: number | null): SearchQueryParams {
    const queryParams: SearchQueryParams = {
      q: this.query(),
      sort: this.sortBy()
    };

    if (minPrice) {
      queryParams['price-min'] = `${minPrice}`;
    }
    if (maxPrice) {
      queryParams['price-max'] = `${maxPrice}`;
    }

    queryParams['sort'] = this.sortBy();
    return queryParams;
  }
}
