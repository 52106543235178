<section>
  @defer (when !loading()) {
    
    @if (showForgotPasswordForm()) {
      <forgot-password-form (onForgetPassword)="showForgotPasswordForm.set(false)" />
    }
  
    @else {
      <div class="login-form-container">
        <h1 class="login-banner">Log in to view results</h1>

        <form name="loginForm" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
          @if (showCmmsLogin()) {
            <button type="button" (click)="loginCMMS()" class="cmms-login-button"><span><img src="assets/images/limble-search-icon.svg" alt="Limble CMMS Logo" /></span>Log In with Limble CMMS</button>
            <div class="or-container">
              <div class="or-line"></div>
              <div class="or-text">or</div>
              <div class="or-line"></div>
            </div>
          }

          <div class="form-group">
            <label for="email" class="input-labels">Email Address</label>
            <input
              type="text"
              class="form-control"
              name="email"
              [(ngModel)]="loginForm.email"
              required
              #email="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
            />
            @if (email.errors && f.submitted) {
              <div class="invalid-feedback">Email is required!</div>
            }
          </div>
          <div class="form-group hoverboundary">
            <span>
              <label for="password" class="input-labels">Password</label>
              <input
                type="password"
                class="form-control"
                name="password"
                [(ngModel)]="loginForm.password"
                required
                minlength="6"
                #password="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
                id="password"
              />
            </span>
            <span class="unhideOnHover">
              <lim-ui-icon
                [icon]="showPassword() ? eyeIcon : eyeSlashIcon"
                class="unhideOnHoverIcon"
                iconSize="small"
                (click)="togglePasswordVisibility()"
              ></lim-ui-icon>
            </span>
          </div>
    
          <div class="password-tools">
            <a (click)="showForgotPasswordForm.set(true)">Forgot Password?</a>
          </div>
    
          <button type="submit" class="login-button">Log In</button>

    
          @if (error()) {
            <div class="error-container">
              {{ error() }}
            </div>
          }
  
          <div class="signup-section">
            <span>Don't have an account yet? </span>
            <a href="https://limblecmms.com/search-waitlist">Sign Up</a>
          </div>
          <div class="legal-terms-section">
            By clicking "Log In" you agree to Limble's
            <a href="https://limblecmms.com/terms-of-service.html" target="_blank">Terms Of Service</a>
            and
            <a href="https://limblecmms.com/privacy.html" target="_blank"> Privacy Policy </a>
          </div>
        </form>
      </div>
    }
  }
  @placeholder {
    <div class="child-container">
      <div class="logo-animation-container">
        <logo-animation [animationSize]="'large'" />
      </div>
    </div>
  }
</section>
